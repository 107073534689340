import { Row, Col, Card, Table, Form } from "react-bootstrap";
import React, { useState } from "react";

import Exercise from "./Exercise";
// Lowerbody 1
import lowerbody1_week1 from "../DBLocal/lowerbody/lowerbody1_week1";
import lowerbody1_week2 from "../DBLocal/lowerbody/lowerbody1_week2";
import lowerbody1_week3 from "../DBLocal/lowerbody/lowerbody1_week3";
import lowerbody1_week4 from "../DBLocal/lowerbody/lowerbody1_week4";
// Lowerbody 2
import lowerbody2_week1 from "../DBLocal/lowerbody/lowerbody2_week1";
import lowerbody2_week2 from "../DBLocal/lowerbody/lowerbody2_week2";
import lowerbody2_week3 from "../DBLocal/lowerbody/lowerbody2_week3";
import lowerbody2_week4 from "../DBLocal/lowerbody/lowerbody2_week4";
// Upperbody 1
import upperbody1_week1 from "../DBLocal/upperbody/upperbody1_week1";
import upperbody1_week2 from "../DBLocal/upperbody/upperbody1_week2";
import upperbody1_week3 from "../DBLocal/upperbody/upperbody1_week3";
import upperbody1_week4 from "../DBLocal/upperbody/upperbody1_week4";
// Upperbody 2
import upperbody2_week1 from "../DBLocal/upperbody/upperbody2_week1";
import upperbody2_week2 from "../DBLocal/upperbody/upperbody2_week2";
import upperbody2_week3 from "../DBLocal/upperbody/upperbody2_week3";
import upperbody2_week4 from "../DBLocal/upperbody/upperbody2_week4";

const WorkoutTable = () => {
  const [selectedWorkout, setSelectedWorkout] = useState("lowerbody1");
  const [selectedWeek, setSelectedWeek] = useState("week1");

  // Map workouts based on the selected week
  const workouts = {
    week1: {
      lowerbody1: { data: lowerbody1_week1, title: "Lower Body 1 Week 1" },
      lowerbody2: { data: lowerbody2_week1, title: "Lower Body 2 Week 1" },
      upperbody1: { data: upperbody1_week1, title: "Upper Body 1 Week 1" },
      upperbody2: { data: upperbody2_week1, title: "Upper Body 2 Week 1" },
    },
    week2: {
      lowerbody1: { data: lowerbody1_week2, title: "Lower Body 1 Week 2" },
      lowerbody2: { data: lowerbody2_week2, title: "Lower Body 2 Week 2" },
      upperbody1: { data: upperbody1_week2, title: "Upper Body 1 Week 2" },
      upperbody2: { data: upperbody2_week2, title: "Upper Body 2 Week 2" },
    },
    week3: {
      lowerbody1: { data: lowerbody1_week3, title: "Lower Body 1 Week 3" },
      lowerbody2: { data: lowerbody2_week3, title: "Lower Body 2 Week 3" },
      upperbody1: { data: upperbody1_week3, title: "Upper Body 1 Week 3" },
      upperbody2: { data: upperbody2_week3, title: "Upper Body 2 Week 3" },
    },
    week4: {
      lowerbody1: { data: lowerbody1_week4, title: "Lower Body 1 Week 4" },
      lowerbody2: { data: lowerbody2_week4, title: "Lower Body 2 Week 4" },
      upperbody1: { data: upperbody1_week4, title: "Upper Body 1 Week 4" },
      upperbody2: { data: upperbody2_week4, title: "Upper Body 2 Week 4" },
    },
    // Add more weeks as needed
  };

  const handleWorkoutChange = (e) => {
    setSelectedWorkout(e.target.value);
  };

  const handleWeekChange = (e) => {
    setSelectedWeek(e.target.value);
  };

  return (
    <Row>
      <Col>
        <Card className="mb-3">
          <Card.Header>
            <Form.Select onChange={handleWeekChange} value={selectedWeek}>
              <option value="week1">Week 1</option>
              <option value="week2">Week 2</option>
              <option value="week3">Week 3</option>
              <option value="week4">Week 4</option>
              {/* Add more weeks as needed */}
            </Form.Select>

            <Form.Select onChange={handleWorkoutChange} value={selectedWorkout}>
              <option value="lowerbody1">Lower Body 1</option>
              <option value="lowerbody2">Lower Body 2</option>
              <option value="upperbody1">Upper Body 1</option>
              <option value="upperbody2">Upper Body 2</option>
            </Form.Select>
          </Card.Header>
          <Card.Body>
            <div className="table-responsive">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Exercise</th>
                    <th>Details</th>
                    <th colSpan="4" style={{ textAlign: "center" }}>
                      Sets
                    </th>
                    <th>Score Type</th>
                    <th>Score</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>1</td>
                    <td>2</td>
                    <td>3</td>
                    <td>4</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {workouts[selectedWeek][selectedWorkout]?.data?.map(
                    (exercise, index) => (
                      <Exercise
                        key={index}
                        name={exercise.name}
                        details={exercise.details}
                        sets={exercise.sets}
                        notes={exercise.notes}
                      />
                    )
                  )}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default WorkoutTable;
