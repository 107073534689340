import React, { useState } from 'react'
import { Container, Card, Form, Button } from 'react-bootstrap'

function BodyFatForm() {
  // Set up state for each input field
  const [formValues, setFormValues] = useState({
    id: '1000843809',
    chest: 10,
    ab: 10,
    thigh: 10,
    tri: 10,
    supra: 10,
    subscap: 10,
    mid: 10,
    age: 45,
  })

  // Generic onChange handler to update state
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormValues({
      ...formValues,
      [name]: value, // Dynamically update the field based on its name
    })
  }

  return (
    <Container className="mt-5">
      <Card className="mx-auto" style={{ maxWidth: '400px' }}>
        <Card.Body>
          <Card.Title className="text-center">Submit Measurements</Card.Title>
          <Form action="/metrics/bodyfat-result" method="get">
            <Form.Group>
              <Form.Label>Gender</Form.Label>
              <Form.Control as="select" name="gender" required>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>ID</Form.Label>
              <Form.Control
                type="number"
                name="id"
                value={formValues.id}
                onChange={handleChange} // Add onChange handler
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Chest (mm)</Form.Label>
              <Form.Control
                type="number"
                name="chest"
                value={formValues.chest}
                onChange={handleChange} // Add onChange handler
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Abdomen (mm)</Form.Label>
              <Form.Control
                type="number"
                name="ab"
                value={formValues.ab}
                onChange={handleChange} // Add onChange handler
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Thigh (mm)</Form.Label>
              <Form.Control
                type="number"
                name="thigh"
                value={formValues.thigh}
                onChange={handleChange} // Add onChange handler
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Triceps (mm)</Form.Label>
              <Form.Control
                type="number"
                name="tri"
                value={formValues.tri}
                onChange={handleChange} // Add onChange handler
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Suprailiac (mm)</Form.Label>
              <Form.Control
                type="number"
                name="supra"
                value={formValues.supra}
                onChange={handleChange} // Add onChange handler
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Subscapular (mm)</Form.Label>
              <Form.Control
                type="number"
                name="subscap"
                value={formValues.subscap}
                onChange={handleChange} // Add onChange handler
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Midaxillary (mm)</Form.Label>
              <Form.Control
                type="number"
                name="mid"
                value={formValues.mid}
                onChange={handleChange} // Add onChange handler
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Age</Form.Label>
              <Form.Control
                type="number"
                name="age"
                value={formValues.age}
                onChange={handleChange} // Add onChange handler
                required
              />
            </Form.Group>
            <Button type="submit" variant="success" block={true}>
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default BodyFatForm
