import React, { createContext, useState, useEffect } from 'react'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({ token: null, user: null })

  // Load token from localStorage when the app loads
  useEffect(() => {
    const storedToken = localStorage.getItem('token')
    if (storedToken) {
      // Ideally, you'd want to verify the token's validity here
      setAuth((prevState) => ({ ...prevState, token: storedToken }))
    }
  }, [])

  const login = (userData, token) => {
    setAuth({ user: userData, token })
    localStorage.setItem('token', token) // Store token locally
  }

  const logout = () => {
    setAuth({ token: null, user: null })
    localStorage.removeItem('token') // Remove token on logout
  }

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
