// Users/UserList.js

import React, { useContext, useEffect, useState } from 'react'
import { getUsers } from '../../services/userService'
import AuthContext from '../../context/AuthContext'

const UserList = () => {
  const { auth } = useContext(AuthContext)
  const [users, setUsers] = useState([])

  useEffect(() => {
    // const fetchUsers = async () => {
    //   try {
    //     const data = await getUsers(auth.token)
    //     console.log('API response:', data)
    //     setUsers(data)
    //   } catch (error) {
    //     console.error(error)
    //     setUsers([])
    //   }
    // }
    const fetchUsers = async () => {
      try {
        const data = await getUsers(auth.token)
        console.log('API response:', data)
        setUsers(Array.isArray(data) ? data : []) // Ensure it's always an array
        // console.log('API response:', data)
      } catch (error) {
        console.error('Failed to fetch users:', error)
        setUsers([]) // Graceful fallback on error
      }
    }

    fetchUsers()
  }, [auth.token])

  return (
    <div>
      <h2>User List</h2>
      <ul>
        {users.map((user) => (
          <li key={user._id}>{user.username}</li>
        ))}
      </ul>
    </div>
  )
}

export default UserList
