import React, { useContext } from 'react'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Dropdown from './Dropdown' // Import the new Dropdown component
import AuthContext from '../../context/AuthContext'

function MyNavbar() {
  const { auth, logout } = useContext(AuthContext)
  const navigate = useNavigate()

  const handleLogout = () => {
    logout()
    navigate('/auth/login') // Redirect to login after logout
  }

  const breneItems = [
    { name: 'Emotions', link: '/mindset/emotion-card-list' },
    { name: 'Emotion Wheel', link: '/mindset/emotion-wheel' },
  ]

  // const polishItems = [
  //   { name: 'Present & Past tense', link: '/polish/verbs' },
  //   { name: 'Animals', link: '/polish/animals' },
  //   { name: 'New Animal', link: '/polish/animals/new' },
  //   { name: 'Months', link: '/polish/months' },
  //   { name: 'Numbers', link: '/polish/numbers' },
  // ]
  return (
    <Navbar bg="light" expand="lg" fixed="top">
      <Navbar.Brand href="/">2024 React</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {/* Other ways without props in notes */}
          {/* Using the new Dropdown component for Brene */}
          <Dropdown title="Brene" items={breneItems} />

          {/* SSR - Server Side Rendering (MEEN) */}
          <Nav.Link href="https://www.hermcore.com/api">Backend App</Nav.Link>

          {/* //yellow Polish Dropdown -- This doesn't handle nested dropdowns yet */}
          {/* <Dropdown title="Polish" items={polishItems} /> */}

          {/* Polish Dropdown with Grammar and Animals */}
          <NavDropdown title="Polish" id="polish-nav-dropdown">
            {/* Grammar Nested Dropdown */}
            <NavDropdown title="Grammar" id="verbs-sub-dropdown">
              {/* Verbs double Nested Dropdown */}
              <NavDropdown title="Verbs" id="verbs-sub-dropdown">
                <NavDropdown.Item as={Link} to="/polish/verbs">
                  Present & Past tense
                </NavDropdown.Item>
              </NavDropdown>
            </NavDropdown>

            {/* Animals Nested Dropdown */}
            <NavDropdown title="Animals" id="Animal-nav-dropdown">
              <NavDropdown.Item as={Link} to="/polish/animals">
                Animals
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/polish/animals/new">
                New Animal
              </NavDropdown.Item>
            </NavDropdown>

            {/* Polish Sites Nested Dropdown */}
            <NavDropdown title="Polish Sites" id="polish-sites-nav-dropdown">
              <NavDropdown.Item
                href="https://translate.google.com/?sl=pl&tl=en&op=translate"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Translate
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://www.duolingo.com/lesson"
                target="_blank"
                rel="noopener noreferrer"
              >
                Duolingo
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown.Item as={Link} to="/polish/months">
              Months
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/polish/numbers">
              Numbers
            </NavDropdown.Item>
          </NavDropdown>

          {/* Training Dropdown */}
          <NavDropdown title="Training" id="training-nav-dropdown">
            <NavDropdown.Item
              href="https://hermotafitness.trainerize.com/app/ExerciseLibrary.aspx?mode=all&amp;level=trainer"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trainerize
            </NavDropdown.Item>

            <NavDropdown title="Herman" id="hermans-nav-dropdown">
              <NavDropdown.Item as={Link} to="/hermans/Dashboard">
                Herman's Dashboard
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/hermans/exercises">
                Herman's Exercises
              </NavDropdown.Item>
            </NavDropdown>
          </NavDropdown>

          {/* Metrics */}
          <NavDropdown title="Metrics" id="metrics-nav-dropdown">
            <NavDropdown.Item as={Link} to="/metrics/bodyfat-form">
              Bodyfat Form
            </NavDropdown.Item>
          </NavDropdown>

          {/* PN */}
          <NavDropdown title="PN" id="pn-nav-dropdown">
            {/* Links and resources */}
            <NavDropdown title="PN Links" id="habits-nav-dropdown">
              <NavDropdown.Item href="https://my.precisionnutrition.com/certification#/414840/archive?page=40">
                PN2 All Lessons
              </NavDropdown.Item>
            </NavDropdown>
            {/* Habits */}
            <NavDropdown title="Habits" id="habits-nav-dropdown">
              <NavDropdown.Item as={Link} to="/pn/habits/findTime">
                1. Find Time
              </NavDropdown.Item>
            </NavDropdown>
            {/* Lessons */}
            <NavDropdown title="Lessons" id="lessons-nav-dropdown">
              <NavDropdown.Item as={Link} to="/pn/lessons/welcomeAccordion">
                1. Welcome
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/pn/lessons/oneHabitMethod">
                2. One habit method
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/pn/lessons/expectations">
                3. Expectations
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/pn/lessons/nutsAndBolts">
                4. NutsAndBolts
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/pn/lessons/gettingMostOutOfStudies"
              >
                5. Getting The Most Out Of Studies
              </NavDropdown.Item>
            </NavDropdown>
          </NavDropdown>

          {/* PN */}
          <NavDropdown title="PN" id="pn-nav-dropdown">
            {/* Links and resources */}
            <NavDropdown.Item href="https://my.precisionnutrition.com/certification#/414840/archive?page=40">
              PN2
            </NavDropdown.Item>
            <NavDropdown.Item href="https://my.precisionnutrition.com/certification#/414840/archive?page=16&variety=Lesson">
              PN2 Lessons
            </NavDropdown.Item>
            <NavDropdown.Item href="https://my.precisionnutrition.com/certification#/414840/archive?page=18&variety=Habit">
              PN2 Habits
            </NavDropdown.Item>
            <NavDropdown.Item href="https://my.precisionnutrition.com/certification#/414840/archive?page=12&variety=Review">
              PN2 Case Studies
            </NavDropdown.Item>

            {/* Habits */}
            <NavDropdown title="Habits" id="habits-nav-dropdown">
              <NavDropdown.Item as={Link} to="#">
                All Habits
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/pn/habits/findTime">
                1. Find Time
              </NavDropdown.Item>
            </NavDropdown>

            {/* Lessons */}
            <NavDropdown title="Lessons" id="lessons-nav-dropdown">
              <NavDropdown.Item as={Link} to="#">
                All Lessons
              </NavDropdown.Item>
            </NavDropdown>
          </NavDropdown>

          {/* react-Bootstrap sass */}
          <NavDropdown title="Css" id="bootstrap-nav-dropdown">
            <NavDropdown title="Cards" id="cards-nav-dropdown">
              <NavDropdown.Item as={Link} to="/css/bootstrap/card-sass">
                Card Sass
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Tables" id="tables-nav-dropdown">
              <NavDropdown.Item as={Link} to="#">
                3. N/A
              </NavDropdown.Item>
            </NavDropdown>
          </NavDropdown>

          {/* React Routes */}

          {/* Javascript Routes */}
          <NavDropdown title="Javascript" id="javascript-nav-dropdown">
            <NavDropdown.Item
              as={Link}
              to="/javascript/mapAllObjToFormAccordion"
            >
              Map All Obj To Form Accordion
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/javascript/mapFirstObject">
              Map First Object
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to="/javascript/mapAllObjDestructureCards"
            >
              Map All Obj Destructure Cards
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/javascript/arrayWithColorObjects">
              ArrayWithColorObjects
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/javascript/arrayLowerBodyOne">
              ArrayLowerBodyOne
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/javascript/practice">
              Practice
            </NavDropdown.Item>
          </NavDropdown>

          {/* Users Routes */}
          <NavDropdown title="Users" id="users-nav-dropdown">
            <NavDropdown.Item as={Link} to="/users">
              Users
            </NavDropdown.Item>
          </NavDropdown>

          {/* Auth Routes */}
          <NavDropdown title="Auth" id="auth-nav-dropdown">
            {auth.user ? (
              <>
                <NavDropdown.Item onClick={handleLogout}>
                  Logout
                </NavDropdown.Item>
              </>
            ) : (
              <>
                <NavDropdown.Item as={Link} to="/auth/login">
                  Login
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/auth/signup">
                  Signup
                </NavDropdown.Item>
              </>
            )}
          </NavDropdown>

          {/* //Yellow Exercise Routes */}
          <NavDropdown title="Exercise" id="exercise-nav-dropdown">
            <NavDropdown.Item as={Link} to="/exercises/createExercise">
              Create Exercise
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/exercises/readAllExercises">
              Exercises
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default MyNavbar
