import React from 'react'
import lowerbody1Data from '../DBLocal/lowerbody/lowerbody1_week1'
import { Container, Form, Row, Col, Card, Button } from 'react-bootstrap'
import './practice.scss'

const MapFirstObject = () => {
  const { name, details, date, notes, sets } = lowerbody1Data[0] // Get the whole first object
  return (
    <>
      <Container>
        {/* Header with background image and text */}
        <div>
          <header className="header">
            <div className="header-overlay">
              <h1 className="header-text">{name}</h1>
            </div>
            <div className="header-image"></div>
          </header>

          {/* Render details of the first object in the Array */}
          <div className="exercise-details">
            <p>{details}</p>
            <p>Date: {date}</p>
            <p>Notes: {notes}</p>
            <ul>
              {sets.map((set, index) => (
                <li key={index}>
                  Load: {set.load}, Reps: {set.reps}, RIR: {set.rir}, Rest:{' '}
                  {set.rest}
                </li>
              ))}
            </ul>
            <Button variant="primary">View More</Button>
          </div>
        </div>
      </Container>
    </>
  )
}

export default MapFirstObject
