// Services/userService.js
import axios from 'axios'

const API_URL =
  process.env.REACT_APP_API_URL || 'http://localhost:5002/react-api' // Default to localhost for dev

// Read all users
export const getUsers = async (token) => {
  console.log(`Getting users`)

  const response = await axios.get(`${API_URL}/users`, {
    headers: { Authorization: `Bearer ${token}` },
  })

  console.log(`User: ${response.data}`, token)
  return response.data
}

// Read user by ID
export const getUserById = async (id, token) => {
  const response = await axios.get(`${API_URL}/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
  return response.data
}

// Create a new user (Admin operation)
export const createUser = async (userData, token) => {
  const response = await axios.post(API_URL, userData, {
    headers: { Authorization: `Bearer ${token}` },
  })
  return response.data
}

// Update user by ID
export const updateUser = async (id, userData, token) => {
  const response = await axios.put(`${API_URL}/${id}`, userData, {
    headers: { Authorization: `Bearer ${token}` },
  })
  return response.data
}

// Delete user by ID
export const deleteUser = async (id, token) => {
  const response = await axios.delete(`${API_URL}/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
  return response.data
}
