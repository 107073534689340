const upperbody1_week1 = [
  {
    name: "Bench Press",
    details: "Barbell",
    sets: [
      { load: "135", reps: 12, rir: 4, rest: "90s" },
      { load: "145", reps: 10, rir: 4, rest: "90s" },
      { load: "155", reps: 8, rir: 4, rest: "90s" },
      { load: "N/A", reps: "N/A", rir: "N/A", rest: "N/A" },
    ],
    notes: "Keep your elbows at a 45-degree angle and push through your heels.",
  },
  {
    name: "Overhead Press",
    details: "Dumbbells",
    sets: [
      { load: "40", reps: 12, rir: 4, rest: "75s" },
      { load: "45", reps: 10, rir: 4, rest: "75s" },
      { load: "50", reps: 8, rir: 4, rest: "75s" },
      { load: "N/A", reps: "N/A", rir: "N/A", rest: "N/A" },
    ],
    notes: "Engage your core and avoid arching your back.",
  },
  {
    name: "Pull-Up",
    details: "Bodyweight",
    sets: [
      { load: "Bodyweight", reps: 8, rir: 4, rest: "90s" },
      { load: "Bodyweight", reps: 8, rir: 4, rest: "90s" },
      { load: "Bodyweight", reps: 6, rir: 4, rest: "90s" },
      { load: "N/A", reps: "N/A", rir: "N/A", rest: "N/A" },
    ],
    notes: "Focus on pulling through your back and not your arms.",
  },
  {
    name: "Bicep Curl",
    details: "Dumbbells",
    sets: [
      { load: "30", reps: 12, rir: 4, rest: "60s" },
      { load: "35", reps: 10, rir: 4, rest: "60s" },
      { load: "40", reps: 8, rir: 4, rest: "60s" },
      { load: "N/A", reps: "N/A", rir: "N/A", rest: "N/A" },
    ],
    notes: "Keep your elbows close to your sides.",
  },
  {
    name: "Tricep Pushdown",
    details: "Cable Machine",
    sets: [
      { load: "50", reps: 12, rir: 4, rest: "60s" },
      { load: "55", reps: 10, rir: 4, rest: "60s" },
      { load: "60", reps: 8, rir: 4, rest: "60s" },
      { load: "N/A", reps: "N/A", rir: "N/A", rest: "N/A" },
    ],
    notes: "Keep your elbows locked in place.",
  },
];

export default upperbody1_week1;
