// frontend/src/components/HermanDashboard.js
import { Container, Row, Col } from "react-bootstrap";

import Profilecard from "./Profilecard";
import MonthlySchedule from "./MonthlySchedule";
import WorkoutTable from "./WorkoutTable";
import MealPlanner from "./MealPlanner";
import WorkoutView from "./WorkoutView";

// HermanDashboard Component
function HermanDashboard() {
  return (
    <Container fluid className="mt-5">
      <Row className="mb-4">
        {/* Profilecard and gravatar */}
        {/* Gravatar and member id */}
        <Profilecard />

        {/* Monthly Schedule  */}
        <MonthlySchedule />
      </Row>
      <Row className="mb-6">
        <Col>
          <WorkoutView />
        </Col>
        <Col>
          <WorkoutView />
        </Col>
      </Row>

      {/* Workout Table with dropdown */}
      <WorkoutTable />

      {/* Meal Planner */}
      <MealPlanner />
    </Container>
  );
}

export default HermanDashboard;
