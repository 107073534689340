import React from "react";
import { Container, Row, Col, Table } from "react-bootstrap";

function Psrp() {
  return (
    <Container className="Psrp" fluid>
      <Row className="justify-content-md-center">
        <Col md="auto">
          <h1 className="text-center">Polish Verbs: Past and Present Tense</h1>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md={10}>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>English Verb</th>
                <th>Polish Verb (Imperfective)</th>
                <th>Polish Verb (Perfective)</th>
                <th>1st Person Singular (Past)</th>
                <th>1st Person Plural (Past)</th>
                <th>1st Person Singular (Present)</th>
                <th>1st Person Plural (Present)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>to be</td>
                <td>być</td>
                <td>-</td>
                <td>byłem / byłam</td>
                <td>byliśmy / byłyśmy</td>
                <td>jestem</td>
                <td>jesteśmy</td>
              </tr>
              <tr>
                <td>to want</td>
                <td>chcieć</td>
                <td>-</td>
                <td>chciałem / chciałam</td>
                <td>chcieliśmy / chcieliśmy</td>
                <td>chcę</td>
                <td>chcemy</td>
              </tr>
              <tr>
                <td>to have</td>
                <td>mieć</td>
                <td>-</td>
                <td>miałem / miałam</td>
                <td>mieliśmy / miałyśmy</td>
                <td>mam</td>
                <td>mamy</td>
              </tr>
              <tr>
                <td>to go (on foot)</td>
                <td>iść</td>
                <td>pójść</td>
                <td>
                  szedłem / szłam
                  <br />
                  poszedłem / poszłam
                </td>
                <td>
                  szliśmy / szłyśmy
                  <br />
                  poszliśmy / poszłyśmy
                </td>
                <td>idę</td>
                <td>idziemy</td>
              </tr>
              <tr>
                <td>to go (by vehicle)</td>
                <td>jechać</td>
                <td>pojechać</td>
                <td>
                  jechałem / jechałam
                  <br />
                  pojechałem / pojechałam
                </td>
                <td>
                  jechaliśmy / jechałyśmy
                  <br />
                  pojechaliśmy / pojechałyśmy
                </td>
                <td>jadę</td>
                <td>jedziemy</td>
              </tr>
              <tr>
                <td>to eat</td>
                <td>jeść</td>
                <td>zjeść</td>
                <td>
                  jadłem / jadłam
                  <br />
                  zjadłem / zjadłam
                </td>
                <td>
                  jedliśmy / jadłyśmy
                  <br />
                  zjedliśmy / zjadłyśmy
                </td>
                <td>jem</td>
                <td>jemy</td>
              </tr>
              <tr>
                <td>to drink</td>
                <td>pić</td>
                <td>wypić</td>
                <td>
                  piłem / piłam
                  <br />
                  wypiłem / wypiłam
                </td>
                <td>
                  piliśmy / piłyśmy
                  <br />
                  wypiliśmy / wypiłyśmy
                </td>
                <td>piję</td>
                <td>pijemy</td>
              </tr>
              <tr>
                <td>to do / make</td>
                <td>robić</td>
                <td>zrobić</td>
                <td>
                  robiłem / robiłam
                  <br />
                  zrobiłem / zrobiłam
                </td>
                <td>
                  robiliśmy / robiłyśmy
                  <br />
                  zrobiliśmy / zrobiłyśmy
                </td>
                <td>robię</td>
                <td>robimy</td>
              </tr>
              <tr>
                <td>to see</td>
                <td>widzieć</td>
                <td>zobaczyć</td>
                <td>
                  widziałem / widziałam
                  <br />
                  zobaczyłem / zobaczyłam
                </td>
                <td>
                  widzieliśmy / widziałyśmy
                  <br />
                  zobaczyliśmy / zobaczyłyśmy
                </td>
                <td>widzę</td>
                <td>widzimy</td>
              </tr>
              <tr>
                <td>to read</td>
                <td>czytać</td>
                <td>przeczytać</td>
                <td>
                  czytałem / czytałam
                  <br />
                  przeczytałem / przeczytałam
                </td>
                <td>
                  czytaliśmy / czytałyśmy
                  <br />
                  przeczytaliśmy / przeczytałyśmy
                </td>
                <td>czytam</td>
                <td>czytamy</td>
              </tr>
              <tr>
                <td>to write</td>
                <td>pisać</td>
                <td>napisać</td>
                <td>
                  pisałem / pisałam
                  <br />
                  napisałem / napisałam
                </td>
                <td>
                  pisaliśmy / pisałyśmy
                  <br />
                  napisaliśmy / napisałyśmy
                </td>
                <td>piszę</td>
                <td>piszemy</td>
              </tr>
              <tr>
                <td>to speak</td>
                <td>mówić</td>
                <td>powiedzieć</td>
                <td>
                  mówiłem / mówiłam
                  <br />
                  powiedziałem / powiedziałam
                </td>
                <td>
                  mówiliśmy / mówiłyśmy
                  <br />
                  powiedzieliśmy / powiedziałyśmy
                </td>
                <td>mówię</td>
                <td>mówimy</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

export default Psrp;
