import { Col, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom"; // Import the Link component
import "./monthly-schedule.scss"

// Training Schedule
const MonthlySchedule = () => {
  return (
    <Col xs={12} md={8}>
      <Card>
        <Card.Header>Monthly Schedule</Card.Header>
        <Card.Body>
          <Table bordered hover responsive>
            <thead>
              <tr>
                <th className="vertical-text">D</th>
                <th>WK 1</th>
                <th>WK 2</th>
                <th>WK 3</th>
                <th>WK 4</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="vertical-text">M</td>
                <td>
                  <Link to="/workout/upperbody1_week1">Upper Body 1</Link>
                </td>
                <td>
                  <Link to="/workout/upperbody1_week2">Upper Body 1</Link>
                </td>
                <td>
                  <Link to="/workout/upperbody1_week3">Upper Body 1</Link>
                </td>
                <td>
                  <Link to="/workout/upperbody1_week4">Upper Body 1</Link>
                </td>
              </tr>
              <tr>
                <td className="vertical-text">T</td>
                <td>
                  <Link to="/workout/lowerbody1_week1">Lower Body 1</Link>
                </td>
                <td>
                  <Link to="/workout/lowerbody1_week2">Lower Body 1</Link>
                </td>
                <td>
                  <Link to="/workout/lowerbody1_week3">Lower Body 1</Link>
                </td>
                <td>
                  <Link to="/workout/lowerbody1_week4">Lower Body 1</Link>
                </td>
              </tr>
              <tr>
                <td className="vertical-text">W</td>
                <td>
                  <Link to="/workout/cardio1_week1">Cardio</Link>
                </td>
                <td>
                  <Link to="/workout/cardio1_week1">Cardio</Link>
                </td>
                <td>
                  <Link to="/workout/cardio1_week1">Cardio</Link>
                </td>
                <td>
                  <Link to="/workout/cardio1_week1">Cardio</Link>
                </td>
              </tr>
              <tr>
                <td className="vertical-text">T</td>
                <td>
                  <Link to="/workout/upperbody2_week1">Upper Body 2</Link>
                </td>
                <td>
                  <Link to="/workout/upperbody2_week2">Upper Body 2</Link>
                </td>
                <td>
                  <Link to="/workout/upperbody2_week3">Upper Body 2</Link>
                </td>
                <td>
                  <Link to="/workout/upperbody2_week4">Upper Body 2</Link>
                </td>
              </tr>
              <tr>
                <td className="vertical-text">F</td>
                <td>
                  <Link to="/workout/lowerbody2_week1">Lower Body 2</Link>
                </td>
                <td>
                  <Link to="/workout/lowerbody2_week2">Lower Body 2</Link>
                </td>
                <td>
                  <Link to="/workout/lowerbody2_week3">Lower Body 2</Link>
                </td>
                <td>
                  <Link to="/workout/lowerbody2_week4">Lower Body 2</Link>
                </td>
              </tr>
              <tr>
                <td className="vertical-text">S</td>
                <td>
                  <Link to="/workout/cardio1_week2">Cardio</Link>
                </td>
                <td>
                  <Link to="/workout/cardio1_week2">Cardio</Link>
                </td>
                <td>
                  <Link to="/workout/cardio1_week2">Cardio</Link>
                </td>
                <td>
                  <Link to="/workout/cardio1_week2">Cardio</Link>
                </td>
              </tr>
              <tr>
                <td className="vertical-text">S</td>
                <td>
                  <Link to="/workout/taichi">Tai Chi</Link>
                </td>
                <td>
                  <Link to="/workout/taichi">Tai Chi</Link>
                </td>
                <td>
                  <Link to="/workout/taichi">Tai Chi</Link>
                </td>
                <td>
                  <Link to="/workout/taichi">Tai Chi</Link>
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default MonthlySchedule;
