import React from 'react';
import { Container, Table } from 'react-bootstrap';

const monthsDeclensions = [
  { month: "Styczeń", nominative: "Styczeń", genitive: "Stycznia", dative: "Styczniowi", accusative: "Styczeń", instrumental: "Styczniem", prepositional: "o Styczniu", locative: "Styczniu" },
  { month: "Luty", nominative: "Luty", genitive: "Lutego", dative: "Lutemu", accusative: "Luty", instrumental: "Lutym", prepositional: "o Lutym", locative: "Luty" },
  { month: "Marzec", nominative: "Marzec", genitive: "Marca", dative: "Marcowi", accusative: "Marzec", instrumental: "Marcem", prepositional: "o Marcu", locative: "Marcu" },
  { month: "Kwiecień", nominative: "Kwiecień", genitive: "Kwietnia", dative: "Kwietniowi", accusative: "Kwiecień", instrumental: "Kwietniem", prepositional: "o Kwietniu", locative: "Kwietniu" },
  { month: "Maj", nominative: "Maj", genitive: "Maja", dative: "Majowi", accusative: "Maj", instrumental: "Majem", prepositional: "o Maju", locative: "Maju" },
  { month: "Czerwiec", nominative: "Czerwiec", genitive: "Czerwca", dative: "Czerwcowi", accusative: "Czerwiec", instrumental: "Czerwcem", prepositional: "o Czerwcu", locative: "Czerwcu" },
  { month: "Lipiec", nominative: "Lipiec", genitive: "Lipca", dative: "Lipcowi", accusative: "Lipiec", instrumental: "Lipcem", prepositional: "o Lipcu", locative: "Lipcu" },
  { month: "Sierpień", nominative: "Sierpień", genitive: "Sierpnia", dative: "Sierpniowi", accusative: "Sierpień", instrumental: "Sierpniem", prepositional: "o Sierpniu", locative: "Sierpniu" },
  { month: "Wrzesień", nominative: "Wrzesień", genitive: "Września", dative: "Wrześniowi", accusative: "Wrzesień", instrumental: "Wrześniem", prepositional: "o Wrześniu", locative: "Wrześniu" },
  { month: "Październik", nominative: "Październik", genitive: "Października", dative: "Październikowi", accusative: "Październik", instrumental: "Październikiem", prepositional: "o Październiku", locative: "Październiku" },
  { month: "Listopad", nominative: "Listopad", genitive: "Listopada", dative: "Listopadowi", accusative: "Listopad", instrumental: "Listopadem", prepositional: "o Listopadzie", locative: "Listopadzie" },
  { month: "Grudzień", nominative: "Grudzień", genitive: "Grudnia", dative: "Grudniowi", accusative: "Grudzień", instrumental: "Grudniem", prepositional: "o Grudniu", locative: "Grudniu" }
];

function MonthsDeclensions() {
  return (
    <Container className="mt-5">
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>Miesiąc</th>
            <th>Mianownik</th>
            <th>Dopełniacz</th>
            <th>Celownik</th>
            <th>Biernik</th>
            <th>Narzędnik</th>
            <th>Miejscownik</th>
            <th>Wołacz</th>
          </tr>
        </thead>
        <tbody>
          {monthsDeclensions.map((item, index) => (
            <tr key={index}>
              <td>{item.month}</td>
              <td>{item.nominative}</td>
              <td>{item.genitive}</td>
              <td>{item.dative}</td>
              <td>{item.accusative}</td>
              <td>{item.instrumental}</td>
              <td>{item.prepositional}</td>
              <td>{item.locative}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default MonthsDeclensions;