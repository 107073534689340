import React from "react";

const EmotionWheel = () => {
  const categories = [
    { name: "Things Are Uncertain Or Too Much", color: "#FF9999" },
    { name: "We're Hurting", color: "#FFCC99" },
    { name: "Life Is Good", color: "#FFFF99" },
    { name: "Things Don't Go As Planned", color: "#CCFF99" },
    { name: "We Fall Short", color: "#99FF99" },
    { name: "To Self-Assess", color: "#99FFCC" },
    { name: "We Compare With Others", color: "#99FFFF" },
    { name: "We Feel Wronged", color: "#99CCFF" },
    { name: "It's Beyond Us", color: "#9999FF" },
    { name: "We Search for Connection", color: "#CC99FF" },
    { name: "Things Aren't What They Seem", color: "#FF99FF" },
    { name: "The Heart Is Open", color: "#FF99CC" },
    { name: "Places We Go", color: "#FFC0CB" },
  ];

  const radius = 400;
  const centerX = 500;
  const centerY = 500;

  return (
    <svg viewBox="0 0 1000 1000" width="1000" height="1000">
      <circle
        cx={centerX}
        cy={centerY}
        r={radius}
        fill="#FFFFFF"
        stroke="#000000"
        strokeWidth="2"
      />
      {categories.map((category, index) => {
        const angle = (index / categories.length) * 2 * Math.PI;
        const endAngle = ((index + 1) / categories.length) * 2 * Math.PI;

        const x1 = centerX + radius * Math.cos(angle);
        const y1 = centerY + radius * Math.sin(angle);
        const x2 = centerX + radius * Math.cos(endAngle);
        const y2 = centerY + radius * Math.sin(endAngle);

        const pathData = [
          `M ${centerX} ${centerY}`,
          `L ${x1} ${y1}`,
          `A ${radius} ${radius} 0 0 1 ${x2} ${y2}`,
          "Z",
        ].join(" ");

        const textAngle = (angle + endAngle) / 2;
        const textRadius = radius * 0.75;
        const textX = centerX + textRadius * Math.cos(textAngle);
        const textY = centerY + textRadius * Math.sin(textAngle);

        return (
          <g key={index}>
            <path
              d={pathData}
              fill={category.color}
              stroke="#000000"
              strokeWidth="1"
            />
            <text
              x={textX}
              y={textY}
              textAnchor="middle"
              transform={`rotate(${
                (textAngle * 180) / Math.PI + 90
              }, ${textX}, ${textY})`}
              fontSize="12"
              fontWeight="bold"
            >
              {category.name}
            </text>
          </g>
        );
      })}
      <circle
        cx={centerX}
        cy={centerY}
        r="100"
        fill="#FFFFFF"
        stroke="#000000"
        strokeWidth="2"
      />
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        fontSize="24"
        fontWeight="bold"
      >
        87 Human
      </text>
      <text
        x={centerX}
        y={centerY + 30}
        textAnchor="middle"
        fontSize="24"
        fontWeight="bold"
      >
        Emotions &
      </text>
      <text
        x={centerX}
        y={centerY + 60}
        textAnchor="middle"
        fontSize="24"
        fontWeight="bold"
      >
        Experiences
      </text>
    </svg>
  );
};

export default EmotionWheel;
