import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { Container, Spinner, Alert } from 'react-bootstrap'

const ExerciseDetail = () => {
  const { id } = useParams() // Get the exercise ID from the route params
  const [exercise, setExercise] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    axios
      //   .get(`http://localhost:5002/react-api/exercises/${id}`)
      .get(`http://localhost:5002/react-api/exercises/66f7161fecd87b93308472f3`)
      .then((response) => {
        setExercise(response.data)
        setLoading(false)
      })
      .catch((err) => {
        setError('Error fetching exercise')
        setLoading(false)
      })
  }, [id])

  if (loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    )
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>
  }

  if (!exercise) {
    return <Alert variant="warning">No exercise found.</Alert>
  }

  return (
    <Container>
      <h1 className="my-4">
        {exercise.name} ({exercise.type})
      </h1>
      {exercise.sets &&
        exercise.sets.map((set, index) => (
          <div key={index}>
            <strong>Set {index + 1}:</strong>
            Load: {set.load} lbs, Reps: {set.reps}, Rest: {set.rest}, RIR:{' '}
            {set.rir}
          </div>
        ))}
    </Container>
  )
}

export default ExerciseDetail
