import React from "react";
import { Container, Accordion, Card } from "react-bootstrap";

const WelcomeAccordion = () => {
  return (
    <Container fluid="md" className="my-4">
      <Card style={{ backgroundColor: "#f8f9fa", borderColor: "#e9ecef" }}>
        <Card.Body>
          <Card.Title as="h1" className="mb-4 text-primary">
            Welcome to your first day of school
          </Card.Title>
          <Card.Text>
            Get to know the program and your first daily practice: Find time.
          </Card.Text>

          <Accordion defaultActiveKey="0">
            {/* Section 1 */}
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Welcome to your first day of school
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: "#e9ecef" }}>
                <p>
                  For the next two weeks, we’ll help you find your way around
                  your new “classroom.”
                </p>
                <p>
                  You'll learn more about how this program will work, and about
                  the other students. We’ll be covering topics like:
                </p>
                <ul>
                  <li>how to use your "Today" home page;</li>
                  <li>what your day-to-day tasks will be;</li>
                  <li>what's expected of you over the next year;</li>
                  <li>
                    how to evaluate your own progress, and how we'll evaluate
                    you; and
                  </li>
                  <li>
                    how to get the most out of this course, personally and
                    professionally.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            {/* Section 2 */}
            <Accordion.Item eventKey="1">
              <Accordion.Header>Daily practice: Find time</Accordion.Header>
              <Accordion.Body style={{ backgroundColor: "#f8f9fa" }}>
                <p>
                  You know this already from your own clients: New Year’s
                  resolution enthusiasm is quickly squashed by late-January
                  reality.
                </p>
                <p>
                  Big dreams and lofty goals aren’t enough. To succeed, you must
                  plan, prepare, and commit to a regular practice.
                </p>
                <p>
                  You’re busy. We know that. So we’ve designed this course to
                  accommodate full schedules, travel, and life’s other
                  inevitable imperfections. (More on that later.)
                </p>
                <p>
                  Grab your calendar. (If you don't have one, get one now.) Look
                  at your schedule. Block off some time for this program, every
                  day.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            {/* Section 3 */}
            <Accordion.Item eventKey="2">
              <Accordion.Header>Your lessons</Accordion.Header>
              <Accordion.Body style={{ backgroundColor: "#e9ecef" }}>
                <p>
                  In addition to daily practices, every day you’ll also get a
                  new lesson. Completing lessons is an important part of your
                  coursework.
                </p>
                <ul>
                  <li>Click on the lesson card when it appears on your "Today" page.</li>
                  <li>Read through the lesson.</li>
                  <li>If there's a question to answer at the end, answer it.</li>
                  <li>Click "I've read this lesson".</li>
                </ul>
                <p>
                  Once you complete a lesson and daily habit, it doesn't
                  disappear forever. It goes into your Course Archive.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            {/* Section 4 */}
            <Accordion.Item eventKey="3">
              <Accordion.Header>What's coming up this week</Accordion.Header>
              <Accordion.Body style={{ backgroundColor: "#f8f9fa" }}>
                <p>Here is a PDF of the course outline. We’ll go through it step by step over the next several days. For now, just glance at it.</p>
                <p>This week:</p>
                <ul>
                  <li>Tomorrow, we’ll tell you more about the one-habit method and how we coach.</li>
                  <li>Wednesday, we’ll go through learning objectives, expectations, and evaluation.</li>
                  <li>Thursday, we’ll tell you about other things you can find on your "Today" page.</li>
                  <li>Friday, we’ll explain how to get the most out of this program.</li>
                  <li>Saturday, we’ll walk you through the self-evaluations and case study submissions.</li>
                  <li>Sunday, we’ll review and give you a look ahead to next week.</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            {/* Section 5 */}
            <Accordion.Item eventKey="4">
              <Accordion.Header>What to do today</Accordion.Header>
              <Accordion.Body style={{ backgroundColor: "#e9ecef" }}>
                <ul>
                  <li>Look at your upcoming schedule. Block off some time for this program, every day.</li>
                  <li>Complete your new daily practice.</li>
                  <li>Read through this lesson.</li>
                </ul>
                <p>
                  Have fun! And welcome aboard! Until tomorrow, The PN Coaching
                  Team.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

        </Card.Body>
      </Card>
    </Container>
  );
};

export default WelcomeAccordion;