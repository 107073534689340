const upperbody2_week1 = [
  {
    name: "Incline Bench Press",
    details: "Barbell",
    sets: [
      { load: "120", reps: 12, rir: 4, rest: "90s" },
      { load: "130", reps: 10, rir: 4, rest: "90s" },
      { load: "140", reps: 8, rir: 4, rest: "90s" },
      { load: "N/A", reps: "N/A", rir: "N/A", rest: "N/A" },
    ],
    notes:
      "Maintain a slight arch in your lower back and push through your heels.",
  },
  {
    name: "Lat Pulldown",
    details: "Cable Machine",
    sets: [
      { load: "80", reps: 12, rir: 4, rest: "75s" },
      { load: "90", reps: 10, rir: 4, rest: "75s" },
      { load: "100", reps: 8, rir: 4, rest: "75s" },
      { load: "N/A", reps: "N/A", rir: "N/A", rest: "N/A" },
    ],
    notes: "Focus on pulling down with your elbows, not your hands.",
  },
  {
    name: "Dumbbell Lateral Raise",
    details: "Dumbbells",
    sets: [
      { load: "20", reps: 12, rir: 4, rest: "60s" },
      { load: "25", reps: 10, rir: 4, rest: "60s" },
      { load: "30", reps: 8, rir: 4, rest: "60s" },
      { load: "N/A", reps: "N/A", rir: "N/A", rest: "N/A" },
    ],
    notes: "Lift with control and don't swing the weights.",
  },
  {
    name: "Seated Row",
    details: "Cable Machine",
    sets: [
      { load: "90", reps: 12, rir: 4, rest: "75s" },
      { load: "100", reps: 10, rir: 4, rest: "75s" },
      { load: "110", reps: 8, rir: 4, rest: "75s" },
      { load: "N/A", reps: "N/A", rir: "N/A", rest: "N/A" },
    ],
    notes:
      "Keep your back straight and focus on pulling with your back muscles.",
  },
  {
    name: "Overhead Tricep Extension",
    details: "Dumbbell",
    sets: [
      { load: "40", reps: 12, rir: 4, rest: "60s" },
      { load: "45", reps: 10, rir: 4, rest: "60s" },
      { load: "50", reps: 8, rir: 4, rest: "60s" },
      { load: "N/A", reps: "N/A", rir: "N/A", rest: "N/A" },
    ],
    notes: "Keep your elbows locked in place and don't flare them out.",
  },
];

export default upperbody2_week1;
