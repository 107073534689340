// src/routes/AnimalsRoutes.js
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import HermanDashboard from '../components/Herman/HermanDashboard'
import WorkoutView from '../components/Herman/WorkoutView' // New workout view component
import Exercises from '../components/Herman/Exercises'
import ExerciseList from '../components/Herman/ExerciseList'
import ExerciseDetail from '../components/Herman/ExerciseDetail'

const HermansRoutes = () => {
  return (
    <Routes>
      <Route path="/Dashboard" element={<HermanDashboard />} />
      <Route path="/exercises" element={<Exercises />} />
      <Route path="/exerciseList" element={<ExerciseList />} />
      <Route path="/exerciseDetail" element={<ExerciseDetail />} />
      {/* New dynamic route for workouts  */}
      <Route path="/workout/:workoutType" element={<WorkoutView />} />
    </Routes>
  )
}

export default HermansRoutes
