import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { AuthProvider } from '../context/AuthContext.js'
import MyNavbar from './Navbar/MyNavbar'

import Dashboard from '../components/Herman/HermanDashboard'
import HermansRoutes from '../routes/HermansRoutes'
import ExerciseRoutes from '../routes/ExerciseRoutes'
import PolishRoutes from '../routes/PolishRoutes'
import JavascriptRoutes from '../routes/JavascriptRoutes'
import PnRoutes from '../routes/PnRoutes'
import MindsetRoutes from '../routes/MindsetRoutes'
import MetricsRoutes from '../routes/MetricsRoutes'
import CssRoutes from '../routes/CssRoutes'
import AuthRoutes from '../routes/AuthRoutes.js'
import UserRoutes from '../routes/UserRoutes.js'
import NotFound from './NotFound.js'

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <MyNavbar />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="*" element={<NotFound />} />
            {/* Modular Routes */}
            <Route path="/exercises/*" element={<ExerciseRoutes />} />
            <Route path="/polish/*" element={<PolishRoutes />} />
            <Route path="/javascript/*" element={<JavascriptRoutes />} />
            <Route path="/pn/*" element={<PnRoutes />} />
            <Route path="/hermans/*" element={<HermansRoutes />} />
            <Route path="/mindset/*" element={<MindsetRoutes />} />
            <Route path="/metrics/*" element={<MetricsRoutes />} />
            <Route path="/css/*" element={<CssRoutes />} />
            <Route path="/auth/*" element={<AuthRoutes />} />
            <Route path="/users/*" element={<UserRoutes />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  )
}

export default App
