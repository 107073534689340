// src/routes/AnimalsRoutes.js
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import BodyFatForm from '../components/Metrics/BodyFatForm'
import BodyFatResult from '../components/Metrics/BodyFatResult'

const AnimalsRoutes = () => {
  return (
    <Routes>
      <Route path="/bodyfat-form" element={<BodyFatForm />} />
      <Route path="/bodyfat-result" element={<BodyFatResult />} />
    </Routes>
  )
}

export default AnimalsRoutes
