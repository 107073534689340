import React from 'react'
import lowerbody1Data from '../DBLocal/lowerbody/lowerbody1_week1'
import { Container, Row, Col, Card } from 'react-bootstrap'
import './practice.scss'

const MapAllObjDestructureCards = () => {
  return (
    <>
      <Container className="my-4">
        <h1 className="text-center text-light bg-dark py-3">
          Lower Body Exercises
        </h1>
        <Row>
          {lowerbody1Data.map(({ name, details, date, notes, sets }, index) => (
            <Col md={6} lg={4} className="mb-4" key={index}>
              <Card className="shadow-sm">
                <Card.Body>
                  <Card.Title className="text-primary">{name}</Card.Title>
                  <Card.Text>
                    <strong>Details:</strong>{' '}
                    {details || 'No details available'}
                  </Card.Text>
                  <Card.Text>
                    <strong>Date:</strong> {date || 'No date available'}
                  </Card.Text>
                  <Card.Text>
                    <strong>Notes:</strong> {notes || 'No notes available'}
                  </Card.Text>
                  <Card.Text>
                    <strong>Sets:</strong>
                    {sets && sets.length > 0 ? (
                      sets.map((set, setIndex) => {
                        // Only render sets that are complete (i.e., have non-empty values)
                        if (set.load || set.reps || set.rir || set.rest) {
                          return (
                            <div key={setIndex} className="mt-2">
                              <p>
                                <strong>Set {setIndex + 1}:</strong>
                                Load: {set.load || 'N/A'}, Reps:{' '}
                                {set.reps || 'N/A'}, RIR: {set.rir || 'N/A'},
                                Rest: {set.rest || 'N/A'}
                              </p>
                            </div>
                          )
                        } else {
                          return null // Skip sets that have no meaningful data
                        }
                      })
                    ) : (
                      <p>No sets available</p>
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  )
}

export default MapAllObjDestructureCards
