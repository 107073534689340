// src/routes/AnimalsRoutes.js
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import FindTime from '../components/PN/Habits/FindTime'
import WelcomeAccordion from '../components/PN/Lessons/WelcomeAccordion'
import OneHabitMethod from '../components/PN/Lessons/OneHabitMethod'
import Expectations from '../components/PN/Lessons/Expectations'
import NutsAndBolts from '../components/PN/Lessons/NutsAndBolts'
import GettingMostOutOfStudies from '../components/PN/Lessons/GettingMostOutOfStudies'

const AnimalsRoutes = () => {
  return (
    <Routes>
      <Route path="/habits/findTime" element={<FindTime />} />
      <Route path="/lessons/welcomeAccordion" element={<WelcomeAccordion />} />
      <Route path="/lessons/oneHabitMethod" element={<OneHabitMethod />} />
      <Route path="/lessons/expectations" element={<Expectations />} />
      <Route path="/lessons/nutsAndBolts" element={<NutsAndBolts />} />
      <Route
        path="/lessons/gettingMostOutOfStudies"
        element={<GettingMostOutOfStudies />}
      />
    </Routes>
  )
}

export default AnimalsRoutes
