import React from 'react'
import lowerbody1_week1 from '../DBLocal/lowerbody/lowerbody1_week1'

function ArrayLowerBodyOne() {
  return (
    <div>
      {lowerbody1_week1.map((exercise, index) => (
        <div key={index}>
          <h3>Exercise {index + 1}</h3>
          <p>
            <strong>Date:</strong> {exercise.date}
          </p>
          <p>
            <strong>Name:</strong> {exercise.name}
          </p>
          <p>
            <strong>Details:</strong> {exercise.details}
          </p>
          <p>
            <strong>Notes:</strong> {exercise.notes}
          </p>
          {/* Table for Sets */}
          <table>
            <thead>
              <tr>
                <th>Load</th>
                <th>Reps</th>
                <th>RIR</th>
                <th>Rest</th>
              </tr>
            </thead>
            <tbody>
              {exercise.sets.map((set, setIndex) => (
                <tr key={setIndex}>
                  <td>{set.load}</td>
                  <td>{set.reps}</td>
                  <td>{set.rir}</td>
                  <td>{set.rest}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <p>
            <strong>Settings:</strong> {exercise.settings}
          </p>
          <hr /> {/* Separator for each exercise */}
        </div>
      ))}
    </div>
  )
}

export default ArrayLowerBodyOne
