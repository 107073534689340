// src/routes/AnimalsRoutes.js
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AnimalsReadAll from '../components/Animals/AnimalsReadAll'
import AnimalCreateForm from '../components/Animals/AnimalCreateForm'
import Noun from '../components/Polish/Noun'
import Psrp from '../components/Polish/pspr'
import MonthsDeclensions from '../components/Polish/MonthsDeclensions'
import NumbersDeclensions from '../components/Polish/NumbersDeclensions'

const PolishRoutes = () => {
  return (
    <Routes>
      <Route path="/animals" element={<AnimalsReadAll />} />
      <Route path="/animals/new" element={<AnimalCreateForm />} />
      <Route path="/verbs" element={<Psrp />} />
      <Route path="/noun" element={<Noun />} />
      <Route path="/months" element={<MonthsDeclensions />} />
      <Route path="/numbers" element={<NumbersDeclensions />} />
    </Routes>
  )
}

export default PolishRoutes
