import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import axios from 'axios'
import { Card, Container, Button } from 'react-bootstrap'

function ReadExercise() {
  const { id } = useParams() // Get the ID from the URL
  const navigate = useNavigate() // For redirecting after deletion
  const [exercise, setExercise] = useState(null)
  // Fetch the API URL from the environment variable
  const API_URL =
    process.env.REACT_APP_API_URL || 'http://localhost:5002/react-api' // Fallback to localhost
  useEffect(() => {
    axios
      .get(`${API_URL}/exercises/${id}`)
      // .get(`http://localhost:5002/react-api/exercises/${id}`)
      .then((response) => {
        setExercise(response.data)
      })
      .catch((error) => {
        console.error('Error fetching exercise:', error)
      })
  }, [id])

  // Handle delete operation
  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this exercise?')) {
      axios
        .delete(`${API_URL}/exercises/${id}`)
        .then(() => {
          alert('Exercise deleted successfully')
          navigate('/exercises/readAllExercises') // Redirect to the exercise list after deletion
        })
        .catch((error) => {
          console.error('Error deleting exercise:', error)
        })
    }
  }

  if (!exercise) {
    return <p>Loading...</p>
  }

  return (
    <Container>
      <Card className="my-4">
        <Card.Body>
          <Card.Title>{exercise.name}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {exercise.type}
          </Card.Subtitle>
          <Card.Text>
            <strong>Details:</strong>{' '}
            {exercise.details || 'No details provided'}
          </Card.Text>
          <Card.Text>
            <strong>Sets:</strong>
            {exercise.sets.map((set, index) => (
              <div key={index}>
                Load: {set.load} lbs, Reps: {set.reps}, Rest: {set.rest}, RIR:{' '}
                {set.rir}
              </div>
            ))}
          </Card.Text>
          <Card.Text>
            <strong>Date:</strong> {exercise.date || 'No date'}
          </Card.Text>
          <Card.Text>
            <strong>Notes:</strong> {exercise.notes || 'No notes'}
          </Card.Text>

          {/* Update Button */}
          <Link to={`/exercises/updateExercise/${id}`}>
            <Button variant="primary" className="mr-2">
              Update Exercise
            </Button>
          </Link>

          {/* Delete Button */}
          <Button variant="danger" onClick={handleDelete}>
            Delete Exercise
          </Button>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default ReadExercise

// import React, { useState, useEffect } from 'react'
// import { useParams } from 'react-router-dom'
// import axios from 'axios'
// import { Card, Container } from 'react-bootstrap'

// function ReadExercise() {
//   const { id } = useParams() // Get the ID from the URL
//   const [exercise, setExercise] = useState(null)

//   useEffect(() => {
//     axios
//       .get(`http://localhost:5002/react-api/exercises/${id}`)
//       .then((response) => {
//         setExercise(response.data)
//       })
//       .catch((error) => {
//         console.error('Error fetching exercise:', error)
//       })
//   }, [id])

//   if (!exercise) {
//     return <p>Loading...</p>
//   }

//   return (
//     <Container>
//       <Card className="my-4">
//         <Card.Body>
//           <Card.Title>{exercise.name}</Card.Title>
//           <Card.Subtitle className="mb-2 text-muted">
//             {exercise.type}
//           </Card.Subtitle>
//           <Card.Text>
//             <strong>Details:</strong>{' '}
//             {exercise.details || 'No details provided'}
//           </Card.Text>
//           <Card.Text>
//             <strong>Sets:</strong>
//             {exercise.sets.map((set, index) => (
//               <div key={index}>
//                 Load: {set.load} lbs, Reps: {set.reps}, Rest: {set.rest}, RIR:{' '}
//                 {set.rir}
//               </div>
//             ))}
//           </Card.Text>
//           <Card.Text>
//             <strong>Date:</strong> {exercise.date || 'No date'}
//           </Card.Text>
//           <Card.Text>
//             <strong>Notes:</strong> {exercise.notes || 'No notes'}
//           </Card.Text>
//         </Card.Body>
//       </Card>
//     </Container>
//   )
// }

// export default ReadExercise
