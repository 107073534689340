import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import axios from "axios";

function AnimalCreateForm() {
  const [formData, setFormData] = useState({
    english: "",
    nominative: "",
    genitive: "",
    dative: "",
    accusative: "",
    instrumental: "",
    locative: "",
    vocative: ""
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Make POST request to the backend to create the new animal
    axios.post("https://www.hermcore.com/api/polish/animals", formData)
      .then((response) => {
        console.log("Animal created successfully:", response.data);
        // Reset form
        setFormData({
          english: "",
          nominative: "",
          genitive: "",
          dative: "",
          accusative: "",
          instrumental: "",
          locative: "",
          vocative: ""
        });
      })
      .catch((error) => {
        console.error("There was an error creating the animal:", error);
      });
  };

  return (
    <Container className="mt-5">
      <h2>Create a New Animal</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>English Name</Form.Label>
          <Form.Control
            type="text"
            name="english"
            value={formData.english}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Nominative</Form.Label>
          <Form.Control
            type="text"
            name="nominative"
            value={formData.nominative}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Genitive</Form.Label>
          <Form.Control
            type="text"
            name="genitive"
            value={formData.genitive}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Dative</Form.Label>
          <Form.Control
            type="text"
            name="dative"
            value={formData.dative}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Accusative</Form.Label>
          <Form.Control
            type="text"
            name="accusative"
            value={formData.accusative}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Instrumental</Form.Label>
          <Form.Control
            type="text"
            name="instrumental"
            value={formData.instrumental}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Locative</Form.Label>
          <Form.Control
            type="text"
            name="locative"
            value={formData.locative}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Vocative</Form.Label>
          <Form.Control
            type="text"
            name="vocative"
            value={formData.vocative}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit" className="mt-3">
          Create Animal
        </Button>
      </Form>
    </Container>
  );
}

export default AnimalCreateForm;