const lowerbody2_week4 = [
  {
    name: "Romanian Deadlift",
    details: "Barbell",
    sets: [
      { load: "100", reps: 12, rir: 4, rest: "90s" },
      { load: "110", reps: 12, rir: 4, rest: "90s" },
      { load: "120", reps: 12, rir: 4, rest: "90s" },
      { load: "N/A", reps: "N/A", rir: "N/A", rest: "N/A" },
    ],
    notes: "Keep your back flat and focus on hip hinge.",
  },
  {
    name: "Leg Curl",
    details: "Machine",
    sets: [
      { load: "80", reps: 15, rir: 4, rest: "60s" },
      { load: "90", reps: 12, rir: 4, rest: "60s" },
      { load: "100", reps: 10, rir: 4, rest: "60s" },
      { load: "N/A", reps: "N/A", rir: "N/A", rest: "N/A" },
    ],
    notes: "Control the lowering phase of each rep.",
  },
  {
    name: "Walking Lunges",
    details: "Dumbbells",
    sets: [
      { load: "40", reps: 12, rir: 4, rest: "75s" },
      { load: "45", reps: 12, rir: 4, rest: "75s" },
      { load: "50", reps: 12, rir: 4, rest: "75s" },
      { load: "N/A", reps: "N/A", rir: "N/A", rest: "N/A" },
    ],
    notes: "Take long strides and push through your front heel.",
  },
  {
    name: "Calf Raise",
    details: "Smith Machine",
    sets: [
      { load: "180", reps: 15, rir: 4, rest: "60s" },
      { load: "200", reps: 15, rir: 4, rest: "60s" },
      { load: "220", reps: 12, rir: 4, rest: "60s" },
      { load: "N/A", reps: "N/A", rir: "N/A", rest: "N/A" },
    ],
    notes: "Hold at the top for 2 seconds.",
  },
  {
    name: "Leg Extension",
    details: "Machine",
    sets: [
      { load: "90", reps: 15, rir: 4, rest: "60s" },
      { load: "100", reps: 12, rir: 4, rest: "60s" },
      { load: "110", reps: 10, rir: 4, rest: "60s" },
      { load: "N/A", reps: "N/A", rir: "N/A", rest: "N/A" },
    ],
    notes: "Extend fully and control the movement back down.",
  },
];

export default lowerbody2_week4;
