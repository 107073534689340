// Services/authService.js
import axios from 'axios'

const API_URL =
  process.env.REACT_APP_API_URL || 'http://localhost:5002/react-api' // Default to localhost for dev

// Sign up
export const signup = async (userData) => {
  const response = await axios.post(`${API_URL}/signup`, userData)
  return response.data
}

// Log in
export const login = async (credentials) => {
  const response = await axios.post(`${API_URL}/login`, credentials)
  return response.data
}
