import React from "react";

function FindTime() {
  return (
    <div className="container mt-5">
      <h2>Welcome to the First Day of Your PN Level 2 Certification Program!</h2>

      <div className="alert alert-info mt-4">
        <p>
          Over the next two weeks, you will familiarize yourself with the course
          structure, expectations, and your fellow students while also engaging
          in daily practices that facilitate your learning.
        </p>
        <p>
          The primary task for today is to <strong>prioritize your education</strong> by scheduling dedicated time for this program. It is essential to set aside
          45-60 minutes daily to revise lessons, complete practices, and work on
          case studies assigned every two weeks, totaling approximately 5-8
          hours a week.
        </p>
        <p>
          This is a <strong>non-negotiable appointment</strong> with yourself for personal and professional growth.
        </p>
      </div>

      <div className="alert alert-warning">
        <p>
          Acknowledging that life can be unpredictable, the program is designed
          with flexibility in mind. While it’s okay to occasionally miss a day,
          maintaining consistency is critical to your success and skill
          development.
        </p>
      </div>

      <div className="alert alert-primary">
        <p>
          Each day, you will encounter a <strong>Daily Practice Card</strong> on your "Today" page, which will prompt you to indicate whether you completed the assigned task.
        </p>
        <p>
          In addition to daily practices, new lessons will be available each day, often accompanied by reflective questions to deepen your understanding and inform your development.
        </p>
        <p>
          Completing a lesson and daily practice helps solidify your grasp of the material, and past lessons will be archived for future reference, ensuring that your learning is cumulative.
        </p>
      </div>

      <div className="alert alert-success">
        <p>
          This week, you'll also receive a <strong>course outline</strong>, explore the coaching methodology, review learning objectives and evaluation criteria, discover additional tools available on your "Today" page, and understand how to maximize your experience in the program.
        </p>
        <p>
          By design, the pacing allows for gradual acclimatization to the material and ensures you’re not overwhelmed.
        </p>
      </div>

      <div className="alert alert-info">
        <p>
          <strong>Today's Focus:</strong> Block time in your schedule for this program and complete your daily practice. Read the lesson thoroughly, and certify your comprehension by clicking "I’ve read this lesson."
        </p>
        <p>
          Embrace the journey ahead, and remember that this is an opportunity
          for growth. <strong>Welcome aboard!</strong>
        </p>
      </div>
    </div>
  );
}

export default FindTime;