// frontend/src/components/AnimalsReadAll.js
import React, { useState, useEffect } from "react";
import { Container, Table, Spinner, Alert } from "react-bootstrap";
import axios from "axios";

function AnimalsReadAll() {
  const [animals, setAnimals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // axios
  //   .get(
  //     "https://fantastic-space-succotash-q7x5jg6w5rg347j9-5000.app.github.dev/api/polish/animals"
  //   )
  //   .then((response) => {
  //     console.log(response.data);
  //   })
  //   .catch((error) => {
  //     console.error("Error fetching animals:", error);
  //   });

  useEffect(() => {
    // Fetch the list of animals from the backend
    axios
      .get("https://www.hermcore.com/api/polish/animals")
      .then((response) => {
        setAnimals(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching animals:", error);
        setError("Error fetching animals");
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <Container className="mt-5">
      <h2>List of Animals</h2>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>English Name</th>
            <th>Nominative</th>
            <th>Genitive</th>
            <th>Dative</th>
            <th>Accusative</th>
            <th>Instrumental</th>
            <th>Locative</th>
            <th>Vocative</th>
          </tr>
        </thead>
        <tbody>
          {animals.map((animal) => (
            <tr key={animal._id}>
              <td>{animal.english}</td>
              <td>{animal.nominative}</td>
              <td>{animal.genitive}</td>
              <td>{animal.dative}</td>
              <td>{animal.accusative}</td>
              <td>{animal.instrumental}</td>
              <td>{animal.locative}</td>
              <td>{animal.vocative}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default AnimalsReadAll;
