import { Col, Card } from "react-bootstrap";
import md5 from "md5";

// Gravatar and member id
const Profilecard = () => {
  const email = "hermansoto@me.com";
  const hash = md5(email.trim().toLowerCase());
  const gravatarUrl = `https://www.gravatar.com/avatar/${hash}?s=150`;
  return (
    <Col xs={12} md={4} className="mb-4">
      {/* Stack on small screens */}
      <Card>
        <Card.Img variant="top" src={gravatarUrl} alt="Avatar" />
        <Card.Body>
          <Card.Title>Herman Soto</Card.Title>
          <Card.Text>
            <strong>Member ID:</strong> 1000843809
            <br />
            <strong>Date of Birth:</strong> January 1, 1990
            <br />
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default Profilecard;
