import React from 'react'
import { useParams } from 'react-router-dom' // To get the workoutType from the URL
import Accordion from 'react-bootstrap/Accordion'
import { Container, Form, Row, Col } from 'react-bootstrap'
import './WorkoutView.scss'
// Lowerbody 1
import lowerbody1_week1 from '../DBLocal/lowerbody/lowerbody1_week1'
import lowerbody1_week2 from '../DBLocal/lowerbody/lowerbody1_week2'
import lowerbody1_week3 from '../DBLocal/lowerbody/lowerbody1_week3'
import lowerbody1_week4 from '../DBLocal/lowerbody/lowerbody1_week4'
// Lowerbody 2
import lowerbody2_week1 from '../DBLocal/lowerbody/lowerbody2_week1'
import lowerbody2_week2 from '../DBLocal/lowerbody/lowerbody2_week2'
import lowerbody2_week3 from '../DBLocal/lowerbody/lowerbody2_week3'
import lowerbody2_week4 from '../DBLocal/lowerbody/lowerbody2_week4'
// Upperbody 1
import upperbody1_week1 from '../DBLocal/upperbody/upperbody1_week1'
import upperbody1_week2 from '../DBLocal/upperbody/upperbody1_week2'
import upperbody1_week3 from '../DBLocal/upperbody/upperbody1_week3'
import upperbody1_week4 from '../DBLocal/upperbody/upperbody1_week4'
// Upperbody 2
import upperbody2_week1 from '../DBLocal/upperbody/upperbody2_week1'
import upperbody2_week2 from '../DBLocal/upperbody/upperbody2_week2'
import upperbody2_week3 from '../DBLocal/upperbody/upperbody2_week3'
import upperbody2_week4 from '../DBLocal/upperbody/upperbody2_week4'

import cardio1_week1 from '../DBLocal/cardio/cardio1_week1'
import cardio1_week2 from '../DBLocal/cardio/cardio1_week2'

const workoutData = {
  lowerbody1_week1,
  lowerbody1_week2,
  lowerbody1_week3,
  lowerbody1_week4,
  lowerbody2_week1,
  lowerbody2_week2,
  lowerbody2_week3,
  lowerbody2_week4,
  upperbody1_week1,
  upperbody1_week2,
  upperbody1_week3,
  upperbody1_week4,
  upperbody2_week1,
  upperbody2_week2,
  upperbody2_week3,
  upperbody2_week4,
  cardio1_week1,
  cardio1_week2,
}

function WorkoutView() {
  const { workoutType } = useParams() // Get the workoutType from the URL

  // Load the correct workout data based on the workoutType
  const workout = workoutData[workoutType]

  if (!workout) {
    return <div>Workout not found!</div>
  }

  // Check if the workout is cardio
  const isCardio = workoutType.includes('cardio')

  return (
    <Container className="mt-5 accordion-no-margin">
      <h2>{workoutType.replace('_', ' ').toUpperCase()}</h2>

      {/* Added custom class for no margin */}
      <Accordion defaultActiveKey="0" className="accordion-no-margin">
        {workout.map((exercise, index) => (
          <Accordion.Item
            eventKey={index.toString()}
            key={index}
            className="m-0 p-0"
          >
            {' '}
            {/* Removed margin/padding from Accordion.Item */}
            <Accordion.Header>{exercise.name}</Accordion.Header>
            <Accordion.Body className="accordion-no-margin">
              <Form>
                {isCardio ? (
                  <>
                    {/* Cardio Workout Form Layout */}
                    <Row className="g-1">
                      <Col xs={6}>
                        <Form.Group controlId={`distance-${index}`}>
                          <Form.Label>Distance</Form.Label>
                          <Form.Control
                            type="text"
                            value={exercise.distance}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId={`time-${index}`}>
                          <Form.Label>Time</Form.Label>
                          <Form.Control
                            type="text"
                            value={exercise.time}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="g-1">
                      <Col xs={6}>
                        <Form.Group controlId={`pace-${index}`}>
                          <Form.Label>Pace</Form.Label>
                          <Form.Control
                            type="text"
                            value={exercise.pace}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId={`heartRate-${index}`}>
                          <Form.Label>Heart Rate</Form.Label>
                          <Form.Control
                            type="text"
                            value={exercise.heartRate}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    {/* Strength Workout Form Layout */}
                    {exercise.sets.map((set, setIndex) => (
                      <Row key={setIndex} className="g-1 mb-1">
                        {' '}
                        {/* Reduced space between fields */}
                        <Col xs={2}>
                          <Form.Group controlId={`set-${index}-${setIndex}`}>
                            <Form.Label>Set</Form.Label>
                            <Form.Control
                              type="text"
                              value={`Set ${setIndex + 1}`}
                              readOnly
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={2}>
                          <Form.Group controlId={`load-${index}-${setIndex}`}>
                            <Form.Label>Load</Form.Label>
                            <Form.Control
                              type="text"
                              value={set.load}
                              readOnly
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={2}>
                          <Form.Group controlId={`reps-${index}-${setIndex}`}>
                            <Form.Label>Reps</Form.Label>
                            <Form.Control
                              type="text"
                              value={set.reps}
                              readOnly
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={3}>
                          <Form.Group controlId={`rir-${index}-${setIndex}`}>
                            <Form.Label>RIR</Form.Label>
                            <Form.Control
                              type="text"
                              value={set.rir}
                              readOnly
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={3}>
                          <Form.Group controlId={`rest-${index}-${setIndex}`}>
                            <Form.Label>Rest</Form.Label>
                            <Form.Control
                              type="text"
                              value={set.rest}
                              readOnly
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    ))}

                    {/* Notes at the bottom */}
                    <Form.Group controlId={`notes-${index}`} className="mt-3">
                      <Form.Label>Notes</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={exercise.notes}
                        readOnly
                      />
                    </Form.Group>
                  </>
                )}
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </Container>
  )
}

export default WorkoutView
