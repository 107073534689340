const upperbody2_week2 = [
    {
      name: "Incline Dumbbell Press",
      details: "Dumbbell",
      sets: [
        { load: "55", reps: 12, rir: 3, rest: "90s" },
        { load: "60", reps: 10, rir: 3, rest: "90s" },
        { load: "65", reps: 8, rir: 3, rest: "90s" },
      ],
      notes: "Keep your back flat on the bench.",
    },
    // Add more exercises here for week 2...
  ];
  
  export default upperbody2_week2;