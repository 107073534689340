// src/routes/AnimalsRoutes.js
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import EmotionCardList from '../components/Mindset/Brene/EmotionCardList'
import EmotionWheel from '../components/Mindset/EmotionWheel'

const AnimalsRoutes = () => {
  return (
    <Routes>
      <Route path="/emotion-card-list" element={<EmotionCardList />} />
      <Route path="/emotion-wheel" element={<EmotionWheel />} />
    </Routes>
  )
}

export default AnimalsRoutes
