const upperbody1_week4 = [
    {
      name: "Bench Press",
      details: "Barbell",
      sets: [
        { load: "150", reps: 10, rir: 1, rest: "90s" },
        { load: "160", reps: 8, rir: 1, rest: "90s" },
        { load: "170", reps: 6, rir: 1, rest: "90s" },
      ],
      notes: "Keep your wrists straight and elbows at a 45-degree angle.",
    },
    // Add more exercises here
  ];
  
  export default upperbody1_week4;