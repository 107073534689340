// src/routes/ExerciseRoutes.js
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import CreateExercise from '../components/Training/Exercise/CreateExercise'
import ReadAllExercises from '../components/Training/Exercise/ReadAllExercises'
import ReadExercise from '../components/Training/Exercise/ReadExercise'
import UpdateExercise from '../components/Training/Exercise/UpdateExercise'
import DeleteExercise from '../components/Training/Exercise/DeleteExercise'

const ExerciseRoutes = () => {
  return (
    <Routes>
      <Route path="/createExercise" element={<CreateExercise />} />
      <Route path="/readAllExercises" element={<ReadAllExercises />} />
      <Route path="/readExercise/:id" element={<ReadExercise />} />
      <Route path="/updateExercise/:id" element={<UpdateExercise />} />
      <Route path="/deleteExercise" element={<DeleteExercise />} />
    </Routes>
  )
}

export default ExerciseRoutes
