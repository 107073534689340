import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Form, Button, Container } from 'react-bootstrap'

function UpdateExercise() {
  const { id } = useParams()
  const navigate = useNavigate()

  // State for all exercise fields
  const [exercise, setExercise] = useState({
    name: '',
    type: '',
    details: '',
    sets: [{ load: '', reps: '', rest: '', rir: '' }],
    date: '',
    notes: '',
    settings: '',
  })

  // Fetch the exercise by ID when the component mounts
  // Fetch the API URL from the environment variable
  const API_URL =
    process.env.REACT_APP_API_URL || 'http://localhost:5002/react-api' // Fallback to localhost
  useEffect(() => {
    axios
      .get(`${API_URL}/exercises/${id}`)
      // .get(`http://localhost:5002/react-api/exercises/66f7161fecd87b93308472f3`)
      .then((response) => setExercise(response.data))
      .catch((error) => console.error('Error fetching exercise:', error))
  }, [id])

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault()
    axios
      // .put(
      //   `http://localhost:5002/react-api/exercises/66f7161fecd87b93308472f3`,
      //   exercise,
      // )
      .put(`${API_URL}/exercises/${id}`, exercise)
      .then((response) => {
        console.log('Update successful:', response.data) // Log the response
        alert('Exercise updated successfully')
        navigate('/exercises/readAllExercises') // Redirect to the exercises list after updating
      })
      .catch((error) => console.error('Error updating exercise:', error))
  }

  // Function to handle changes in sets
  const handleSetChange = (index, field, value) => {
    const updatedSets = [...exercise.sets]
    updatedSets[index][field] = value
    setExercise({ ...exercise, sets: updatedSets })
  }

  // Add a new set
  const addSet = () => {
    setExercise({
      ...exercise,
      sets: [...exercise.sets, { load: '', reps: '', rest: '', rir: '' }],
    })
  }

  return (
    <Container>
      <h1>Update Exercise</h1>
      <Form onSubmit={handleSubmit}>
        {/* Name Field */}
        <Form.Group controlId="name">
          <Form.Label>Exercise Name</Form.Label>
          <Form.Control
            type="text"
            value={exercise.name}
            onChange={(e) => setExercise({ ...exercise, name: e.target.value })}
            required
          />
        </Form.Group>

        {/* Type Field */}
        <Form.Group controlId="type">
          <Form.Label>Exercise Type</Form.Label>
          <Form.Control
            type="text"
            value={exercise.type}
            onChange={(e) => setExercise({ ...exercise, type: e.target.value })}
            required
          />
        </Form.Group>

        {/* Details Field */}
        <Form.Group controlId="details">
          <Form.Label>Exercise Details</Form.Label>
          <Form.Control
            type="text"
            value={exercise.details}
            onChange={(e) =>
              setExercise({ ...exercise, details: e.target.value })
            }
          />
        </Form.Group>

        {/* Date Field */}
        <Form.Group controlId="date">
          <Form.Label>Date</Form.Label>
          <Form.Control
            type="date"
            value={exercise.date}
            onChange={(e) => setExercise({ ...exercise, date: e.target.value })}
          />
        </Form.Group>

        {/* Notes Field */}
        <Form.Group controlId="notes">
          <Form.Label>Notes</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={exercise.notes}
            onChange={(e) =>
              setExercise({ ...exercise, notes: e.target.value })
            }
          />
        </Form.Group>

        {/* Settings Field */}
        <Form.Group controlId="settings">
          <Form.Label>Settings</Form.Label>
          <Form.Control
            type="text"
            value={exercise.settings}
            onChange={(e) =>
              setExercise({ ...exercise, settings: e.target.value })
            }
          />
        </Form.Group>

        {/* Sets Section */}
        <h4>Sets</h4>
        {exercise.sets.map((set, index) => (
          <div key={index} className="mb-3">
            <Form.Group controlId={`set-load-${index}`}>
              <Form.Label>Load (lbs)</Form.Label>
              <Form.Control
                type="number"
                value={set.load}
                onChange={(e) => handleSetChange(index, 'load', e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId={`set-reps-${index}`}>
              <Form.Label>Reps</Form.Label>
              <Form.Control
                type="number"
                value={set.reps}
                onChange={(e) => handleSetChange(index, 'reps', e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId={`set-rest-${index}`}>
              <Form.Label>Rest (seconds)</Form.Label>
              <Form.Control
                type="text"
                value={set.rest}
                onChange={(e) => handleSetChange(index, 'rest', e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId={`set-rir-${index}`}>
              <Form.Label>RIR (Reps in Reserve)</Form.Label>
              <Form.Control
                type="number"
                value={set.rir}
                onChange={(e) => handleSetChange(index, 'rir', e.target.value)}
                required
              />
            </Form.Group>
          </div>
        ))}
        <Button variant="secondary" onClick={addSet}>
          Add Another Set
        </Button>

        {/* Submit Button */}
        <Button variant="primary" type="submit" className="mt-4">
          Update
        </Button>
      </Form>
    </Container>
  )
}

export default UpdateExercise

// import React, { useState, useEffect } from 'react'
// import { useParams, useNavigate } from 'react-router-dom' // Updated to useNavigate
// import axios from 'axios'

// function UpdateExercise() {
//   const { id } = useParams()
//   const navigate = useNavigate() // Updated to useNavigate
//   const [exercise, setExercise] = useState({
//     name: '',
//     sets: [],
//   })

//   useEffect(() => {
//     axios
//       .get(`http://localhost:5002/react-api/exercises/${id}`)
//       .then((response) => setExercise(response.data))
//       .catch((error) => console.error('Error fetching exercise:', error))
//   }, [id])

//   const handleSubmit = (e) => {
//     e.preventDefault()
//     axios
//       .put(`http://localhost:5002/react-api/exercises/${id}`, exercise)
//       .then(() => {
//         // Replacing history.push with navigate
//         navigate('/exercises') // Redirect to the list of exercises after updating
//       })
//       .catch((error) => console.error('Error updating exercise:', error))
//   }

//   return (
//     <form onSubmit={handleSubmit}>
//       <label>
//         Exercise Name:
//         <input
//           type="text"
//           value={exercise.name}
//           onChange={(e) => setExercise({ ...exercise, name: e.target.value })}
//         />
//       </label>
//       {/* Add more fields for exercise sets, etc. */}
//       <button type="submit">Update Exercise</button>
//     </form>
//   )
// }

// export default UpdateExercise
