import React from "react";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

function Dropdown({ title, items }) {
  return (
    <NavDropdown title={title} id={`${title.toLowerCase()}-nav-dropdown`}>
      {items.map((item, index) => (
        <NavDropdown.Item as={Link} to={item.link} key={index}>
          {item.name}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
}

export default Dropdown;
