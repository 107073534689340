import React from 'react';
import { Container, Table } from 'react-bootstrap';

const numbersDeclensions = [
  { number: "One", nominative: "Jeden", genitive: "Jednego", dative: "Jednemu", accusative: "Jeden", instrumental: "Jednym", prepositional: "o Jednym", locative: "Jeden" },
  { number: "Two", nominative: "Dwa", genitive: "Dwóch", dative: "Dwóm", accusative: "Dwa", instrumental: "Dwoma", prepositional: "o Dwóch", locative: "Dwa" },
  { number: "Three", nominative: "Trzy", genitive: "Trzech", dative: "Trzem", accusative: "Trzy", instrumental: "Trzema", prepositional: "o Trzech", locative: "Trzy" },
  { number: "Four", nominative: "Cztery", genitive: "Czterech", dative: "Czterem", accusative: "Cztery", instrumental: "Czterema", prepositional: "o Czterech", locative: "Cztery" },
  { number: "Five", nominative: "Pięć", genitive: "Pięciu", dative: "Pięciu", accusative: "Pięć", instrumental: "Pięcioma", prepositional: "o Pięciu", locative: "Pięć" },
  { number: "Six", nominative: "Sześć", genitive: "Sześciu", dative: "Sześciu", accusative: "Sześć", instrumental: "Sześcioma", prepositional: "o Sześciu", locative: "Sześć" },
  { number: "Seven", nominative: "Siedem", genitive: "Siedmiu", dative: "Siedmiu", accusative: "Siedem", instrumental: "Siedmioma", prepositional: "o Siedmiu", locative: "Siedem" },
  { number: "Eight", nominative: "Osiem", genitive: "Ośmiu", dative: "Ośmiu", accusative: "Osiem", instrumental: "Ośmioma", prepositional: "o Ośmiu", locative: "Osiem" },
  { number: "Nine", nominative: "Dziewięć", genitive: "Dziewięciu", dative: "Dziewięciu", accusative: "Dziewięć", instrumental: "Dziewięcioma", prepositional: "o Dziewięciu", locative: "Dziewięć" },
  { number: "Ten", nominative: "Dziesięć", genitive: "Dziesięciu", dative: "Dziesięciu", accusative: "Dziesięć", instrumental: "Dziesięcioma", prepositional: "o Dziesięciu", locative: "Dziesięć" },
  { number: "Eleven", nominative: "Jedenaście", genitive: "Jedenastu", dative: "Jedenastu", accusative: "Jedenaście", instrumental: "Jedenastoma", prepositional: "o Jedenastu", locative: "Jedenaście" },
  { number: "Twelve", nominative: "Dwanaście", genitive: "Dwunastu", dative: "Dwunastu", accusative: "Dwanaście", instrumental: "Dwunastoma", prepositional: "o Dwunastu", locative: "Dwanaście" },
  { number: "Thirteen", nominative: "Trzynaście", genitive: "Trzynastu", dative: "Trzynastu", accusative: "Trzynaście", instrumental: "Trzynastoma", prepositional: "o Trzynastu", locative: "Trzynaście" },
  { number: "Fourteen", nominative: "Czternaście", genitive: "Czternastu", dative: "Czternastu", accusative: "Czternaście", instrumental: "Czternastoma", prepositional: "o Czternastu", locative: "Czternaście" },
  { number: "Fifteen", nominative: "Piętnaście", genitive: "Piętnastu", dative: "Piętnastu", accusative: "Piętnaście", instrumental: "Piętnastoma", prepositional: "o Piętnastu", locative: "Piętnaście" },
  { number: "Sixteen", nominative: "Szesnaście", genitive: "Szesnastu", dative: "Szesnastu", accusative: "Szesnaście", instrumental: "Szesnastoma", prepositional: "o Szesnastu", locative: "Szesnaście" },
  { number: "Seventeen", nominative: "Siedemnaście", genitive: "Siedemnastu", dative: "Siedemnastu", accusative: "Siedemnaście", instrumental: "Siedemnastoma", prepositional: "o Siedemnastu", locative: "Siedemnaście" },
  { number: "Eighteen", nominative: "Osiemnaście", genitive: "Osiemnastu", dative: "Osiemnastu", accusative: "Osiemnaście", instrumental: "Osiemnastoma", prepositional: "o Osiemnastu", locative: "Osiemnaście" },
  { number: "Nineteen", nominative: "Dziewiętnaście", genitive: "Dziewiętnastu", dative: "Dziewiętnastu", accusative: "Dziewiętnaście", instrumental: "Dziewiętnastoma", prepositional: "o Dziewiętnastu", locative: "Dziewiętnaście" },
  { number: "Twenty", nominative: "Dwadzieścia", genitive: "Dwudziestu", dative: "Dwudziestu", accusative: "Dwadzieścia", instrumental: "Dwudziestoma", prepositional: "o Dwudziestu", locative: "Dwadzieścia" }
];

function NumbersDeclensions() {
  return (
    <Container className="mt-5">
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>Number</th>
            <th>Mianownik</th>
            <th>Dopełniacz</th>
            <th>Celownik</th>
            <th>Biernik</th>
            <th>Narzędnik</th>
            <th>Miejscownik</th>
            <th>Wołacz</th>
          </tr>
        </thead>
        <tbody>
          {numbersDeclensions.map((item, index) => (
            <tr key={index}>
              <td>{item.number}</td>
              <td>{item.nominative}</td>
              <td>{item.genitive}</td>
              <td>{item.dative}</td>
              <td>{item.accusative}</td>
              <td>{item.instrumental}</td>
              <td>{item.prepositional}</td>
              <td>{item.locative}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default NumbersDeclensions;