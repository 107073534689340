const cardio1_week1 = [
  {
    name: 'Cycling',
    distance: '10 miles',
    time: '60 min',
    pace: '6:00/mile',
    heartRate: '130 bpm',
    notes: 'Maintain a high cadence.',
  },
]

export default cardio1_week1
