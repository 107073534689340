import React, { useEffect, useState } from 'react';
import { Container, Card } from 'react-bootstrap';

function BodyFatResult() {
  const [bodyFatPercentage, setBodyFatPercentage] = useState(null);
  const [id, setId] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    const gender = urlParams.get("gender");
    const chest = parseFloat(urlParams.get("chest"));
    const ab = parseFloat(urlParams.get("ab"));
    const thigh = parseFloat(urlParams.get("thigh"));
    const tri = parseFloat(urlParams.get("tri"));
    const supra = parseFloat(urlParams.get("supra"));
    const subscap = parseFloat(urlParams.get("subscap"));
    const mid = parseFloat(urlParams.get("mid"));
    const age = parseInt(urlParams.get("age"), 10);

    setId(id);

    const sumOfSkinfolds = chest + ab + thigh + tri + supra + subscap + mid;
    let bodyDensity;

    if (gender === "male") {
      bodyDensity =
        1.112 -
        0.00043499 * sumOfSkinfolds +
        0.00000055 * Math.pow(sumOfSkinfolds, 2) -
        0.00028826 * age;
    } else {
      bodyDensity =
        1.097 -
        0.00046971 * sumOfSkinfolds +
        0.00000056 * Math.pow(sumOfSkinfolds, 2) -
        0.00012828 * age;
    }

    const bodyFat = ((4.95 / bodyDensity - 4.5) * 100).toFixed(2);
    setBodyFatPercentage(bodyFat);
  }, []);

  return (
    <Container className="mt-5">
      <Card className="mx-auto" style={{ maxWidth: '400px' }}>
        <Card.Body>
          <Card.Title className="text-center">Estimated Body Fat Percentage</Card.Title>
          {id && (
            <Card.Text className="text-center">
              <strong>ID: </strong> {id}
            </Card.Text>
          )}
          <Card.Text className="text-center font-weight-bold">
            {bodyFatPercentage !== null ? `${bodyFatPercentage}%` : 'Calculating...'}
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default BodyFatResult;