import React from 'react'
import { Container, Accordion, Card } from 'react-bootstrap'

const GettingMostOutOfStudies = () => {
  return (
    <Container fluid="md" className="my-4">
      <Card style={{ backgroundColor: '#f8f9fa', borderColor: '#e9ecef' }}>
        <Card.Body>
          <Card.Title as="h1" className="mb-4 text-primary">
            Getting the Most Out of Your Studies
          </Card.Title>
          <Card.Text>How we'll evaluate and assess your progress.</Card.Text>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <span style={{ color: '#007bff', fontWeight: 'bold' }}>
                  How we'll assess you
                </span>
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: '#e9ecef' }}>
                <p>
                  Yesterday, we explained a little about how assignments and
                  quizzes will work. Today, we’ll discuss evaluation and
                  assessment in more detail.
                </p>
                <p>
                  This program is pass or no-pass. Passing is based on several
                  components:
                </p>
                <ul>
                  <li>Completing lessons</li>
                  <li>Completing daily practices</li>
                  <li>Completing quizzes</li>
                  <li>Biweekly case study problems</li>
                </ul>
                <p>
                  We’ll give you lots of reminders about how this all works, and
                  when things are due.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <span style={{ color: '#28a745', fontWeight: 'bold' }}>
                  Evaluation Breakdown
                </span>
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: '#f8f9fa' }}>
                <p>
                  The program works much like an online course, but there’s no
                  formal "grade." It’s a pass or no-pass system based on the
                  following:
                </p>
                <ul>
                  <li>
                    <strong>Lessons and Daily Practices:</strong> You must
                    complete at least 75% of lessons and daily practices to pass
                    the course.
                  </li>
                  <li>
                    <strong>Quizzes:</strong> 75% completion of quizzes is
                    required, but quiz scores do not count toward the final
                    evaluation.
                  </li>
                  <li>
                    <strong>Case Studies:</strong> You must submit and pass 75%
                    of case study assignments.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <span style={{ color: '#17a2b8', fontWeight: 'bold' }}>
                  Quizzes
                </span>
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: '#e9ecef' }}>
                <p>
                  Quizzes will appear on Sundays, covering the previous two
                  weeks' material. There are 6 quizzes in total. Although quiz
                  scores don’t affect your final evaluation, completing them is
                  essential for self-assessment.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <span style={{ color: '#ffc107', fontWeight: 'bold' }}>
                  Case Study Assignments
                </span>
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: '#f8f9fa' }}>
                <p>
                  Every second Thursday, a case study assignment will be
                  introduced. You will have two weeks to complete it. You need
                  to submit and pass 75% of case study assignments to pass the
                  course. There will be a total of 17 case studies, and you can
                  skip or fail up to 4 of them.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <span style={{ color: '#dc3545', fontWeight: 'bold' }}>
                  Lesson and Habit Completion
                </span>
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: '#e9ecef' }}>
                <p>
                  Lesson completion and daily practice participation must reach
                  75% for a pass. If you miss a day, you can always catch up
                  later by checking your Course Archive.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <span style={{ color: '#007bff', fontWeight: 'bold' }}>
                  Group Discussion and Peer Review
                </span>
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: '#f8f9fa' }}>
                <p>
                  Group discussion is a key part of the program. Peer review
                  helps build professional integrity, collaboration, and
                  community. Don’t be afraid to ask questions or provide
                  feedback in the private Facebook group.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6">
              <Accordion.Header>
                <span style={{ color: '#28a745', fontWeight: 'bold' }}>
                  Self-Assessment
                </span>
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: '#e9ecef' }}>
                <p>
                  Every few weeks, starting from Week 3, we’ll ask you to assess
                  your own progress. This includes reflecting on your goals,
                  priorities, and areas for improvement.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7">
              <Accordion.Header>
                <span style={{ color: '#17a2b8', fontWeight: 'bold' }}>
                  Academic Honesty
                </span>
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: '#f8f9fa' }}>
                <p>
                  We trust you to act with integrity. Cheating or cutting
                  corners only undermines your progress. Just like with your
                  clients, consistency and honesty lead to success.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="8">
              <Accordion.Header>
                <span style={{ color: '#ffc107', fontWeight: 'bold' }}>
                  What to do today
                </span>
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: '#e9ecef' }}>
                <ul>
                  <li>Review the assessment and evaluation requirements.</li>
                  <li>
                    Wrap your head around how it all works, and remember the
                    “75% minimum.”
                  </li>
                  <li>
                    Consider how you can contribute meaningfully to the group.
                  </li>
                  <li>
                    Mark down the due dates of upcoming case study assignments.
                  </li>
                  <li>Tick off your daily practice for today.</li>
                </ul>
                <p>Until tomorrow,</p>
                <p>
                  <strong>The PN Coaching Team</strong>
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default GettingMostOutOfStudies
