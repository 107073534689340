import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { ListGroup, Container, Spinner, Alert } from 'react-bootstrap'

const ExerciseList = () => {
  const [exercises, setExercises] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  // Fetch exercises from API
  useEffect(() => {
    axios
      .get('http://localhost:5002/react-api/exercises')
      .then((response) => {
        setExercises(response.data)
        setLoading(false)
      })
      .catch((err) => {
        setError('Error fetching exercises')
        setLoading(false)
      })
  }, [])

  if (loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    )
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>
  }

  return (
    <Container>
      <h1 className="my-4">Exercise List</h1>
      <ListGroup>
        {exercises.map((exercise) => (
          <ListGroup.Item key={exercise._id}>
            <h5>
              {exercise.name} ({exercise.type})
            </h5>
            {exercise.sets.map((set, index) => (
              <div key={index}>
                <strong>Set {index + 1}:</strong>
                Load: {set.load} lbs, Reps: {set.reps}, Rest: {set.rest}, RIR:{' '}
                {set.rir}
              </div>
            ))}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Container>
  )
}

export default ExerciseList
