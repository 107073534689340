import React from 'react'
import { Container, Accordion, Card } from 'react-bootstrap'

const OneHabitMethod = () => {
  return (
    <Container fluid="md" className="my-4">
      <Card style={{ backgroundColor: '#f8f9fa', borderColor: '#e9ecef' }}>
        <Card.Body>
          <Card.Title as="h1" className="mb-4 text-primary">
            About the Program
          </Card.Title>
          <Card.Text>
            An introduction to the one-habit method: Less is more.
          </Card.Text>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <span style={{ color: '#007bff', fontWeight: 'bold' }}>
                  The one-habit method
                </span>
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: '#e9ecef' }}>
                <p>
                  Day 2! Ready to get your learnin’ on? Of course you are! So
                  let’s get started!
                </p>
                <p>
                  <strong>Slowly.</strong>
                </p>
                <p>Yes, slowly. Here’s why.</p>
                <blockquote className="blockquote">
                  <p>
                    In this life we cannot do great things. We can only do small
                    things with great love.— Mother Teresa
                  </p>
                </blockquote>
                <p>
                  Our Precision Nutrition Coaching program is massively
                  successful because of habit-based coaching. This is a simple
                  yet powerful method:
                </p>
                <ul>
                  <li>We present ONE simple task at a time.</li>
                  <li>
                    Clients focus on and practice this one task every day for
                    two weeks.
                  </li>
                </ul>
                <p>
                  We’re going to do the same thing with you — using different
                  habits, of course. Every two weeks, we’ll give you a new
                  “daily practice.”
                </p>
                <p>
                  These will build a foundation of coaching, step by small step.
                  Over time, you get comfortable with the new practices and
                  ensure that they’re as routine as brushing your teeth.
                </p>
                <p>
                  Stay open to this. Have "beginner's mind." Show up ready to
                  play the game and have fun. We coach this way because it
                  works.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <span style={{ color: '#28a745', fontWeight: 'bold' }}>
                  Excellence is built on mastery of fundamentals first
                </span>
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: '#f8f9fa' }}>
                <p>
                  You can't learn to ride a bike from a book. At some point, you
                  have to get on that bike, wobble around, fall over, and figure
                  it out by doing.
                </p>
                <p>Same is true with coaching.</p>
                <p>
                  Knowledge and information won't help you if you can't apply
                  them. Or can't choose what's most important.
                </p>
                <p>
                  This program is about daily action with purpose. Deliberate
                  repetition deepens your expertise and helps you re-commit to
                  the essential tasks that are part of an effective coaching
                  process.
                </p>
                <p>Let yourself be a beginner. Let yourself just... play.</p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <span style={{ color: '#17a2b8', fontWeight: 'bold' }}>
                  Lessons and practices: The one-two punch to greatness
                </span>
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: '#e9ecef' }}>
                <p>
                  As you know by now, when you log in to your "Today" page,
                  you’ll see a few things in focus, at the top:
                </p>
                <ul>
                  <li>
                    The day’s lesson (i.e., the thing you’re reading right now)
                  </li>
                  <li>The day’s practice</li>
                </ul>
                <p>
                  The daily practice will be a small task aimed at enhancing
                  your day-to-day coaching practice. Plus, there will be a few
                  more things:
                </p>
                <ul>
                  <li>
                    Every second Thursday, starting in Week 3, the lesson will
                    introduce a case study assignment.
                  </li>
                  <li>
                    Every two weeks, starting in Week 5, you'll either hand in
                    an assignment or complete a short quiz.
                  </li>
                  <li>
                    Every few weeks, starting in Week 6, there will be a short
                    self-assessment.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <span style={{ color: '#ffc107', fontWeight: 'bold' }}>
                  Completing lessons and daily practices
                </span>
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: '#f8f9fa' }}>
                <p>
                  On your Today page, you’ll see two cards: lessons (green
                  cards) and daily practices (blue cards).
                </p>
                <p>
                  Each day you’ll be asked to complete the lesson and do your
                  practice.
                </p>
                <h5>Lesson questions</h5>
                <p>
                  After reading your lesson, sometimes we'll ask you to answer a
                  few quick questions. Your coaching team will be reviewing your
                  responses.
                </p>

                <h5>Did you do your daily practice today?</h5>
                <p>
                  Your daily practice for this week is “Find time.” If you
                  booked time for PN work, tick off the habit as completed.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <span style={{ color: '#dc3545', fontWeight: 'bold' }}>
                  How to become a supercoach
                </span>
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: '#e9ecef' }}>
                <blockquote className="blockquote">
                  <p>80% of success is showing up.—Woody Allen</p>
                </blockquote>
                <p>
                  Nobody becomes a supercoach overnight. Just like sports, you
                  have to show up to practice and do your drills, consistently.
                </p>
                <p>
                  Show up every day, read your lessons, work on your practices,
                  and enjoy the ride.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <span style={{ color: '#007bff', fontWeight: 'bold' }}>
                  Focus on the present
                </span>
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: '#f8f9fa' }}>
                <p>
                  One great benefit of the habit-based coaching style is that it
                  keeps us focused on the present — on the one or two things we
                  can do right now to ensure our success.
                </p>
                <p>Today, we suggest you just:</p>
                <ul>
                  <li>Complete today's lesson and record it as completed.</li>
                  <li>
                    Look at your upcoming schedule and book time for PN work.
                  </li>
                  <li>Review the course outline.</li>
                  <li>Stay focused on the present.</li>
                  <li>Ask for help if you need it.</li>
                </ul>
                <p>Until tomorrow,</p>
                <p>
                  <strong>The PN Coaching Team</strong>
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default OneHabitMethod
