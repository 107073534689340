// src/routes/AnimalsRoutes.js
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import CardSass from '../components/Bootstrap5Components/CardSass/CardSass'

const CssRoutes = () => {
  return (
    <Routes>
      <Route path="/bootstrap/card-sass" element={<CardSass />} />
    </Routes>
  )
}

export default CssRoutes
