import React from "react";
import { Container, Card, Accordion } from "react-bootstrap";

const Expectations = () => {
  return (
    <Container fluid="md" className="my-4">
      <Card style={{ backgroundColor: "#f8f9fa", borderColor: "#e9ecef" }}>
        <Card.Body>
          <Card.Title as="h1" className="mb-4 text-primary">
            Great Expectations
          </Card.Title>
          <Card.Text>
            Clear expectations are an important part of any good coaching
            practice. Clients should understand what you expect from them, and
            what they should reasonably expect from themselves. Likewise, this
            program has expectations too. Here’s what you can expect from us,
            and what we expect from you.
          </Card.Text>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header
                style={{ backgroundColor: "#007bff", color: "#fff" }}
              >
                <span style={{ color: "#007bff", fontWeight: "bold" }}>
                  What you can expect to learn
                </span>
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: "#e9ecef" }}>
                <ul>
                  <li>Be a self-aware and self-questioning coach.</li>
                  <li>Develop strong working relationships with clients.</li>
                  <li>
                    Guide clients through all stages of the nutritional coaching
                    and consultation process.
                  </li>
                  <li>Understand what’s truly important.</li>
                  <li>
                    Use the right tools and techniques for the job at hand.
                  </li>
                  <li>
                    Treat clients as unique individuals, addressing their needs
                    holistically:
                    <ul>
                      <li>
                        sex/gender, age, ethnicity, culture, and other
                        demographic factors
                      </li>
                      <li>activity limitations and disability</li>
                      <li>stage of readiness for change</li>
                      <li>confidence and preparedness</li>
                      <li>preferred coaching and learning styles</li>
                    </ul>
                  </li>
                  <li>Work effectively on your own and as part of a team.</li>
                  <li>Solve complex problems thoughtfully.</li>
                  <li>Communicate clearly and effectively.</li>
                  <li>
                    Act, work, and present yourself as a respected professional.
                  </li>
                  <li>Develop your own self-sustaining “growth practice.”</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <span style={{ color: "#28a745", fontWeight: "bold" }}>
                  What we expect from you
                </span>
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: "#f8f9fa" }}>
                <ul>
                  <li>
                    Dedicate at least 45–60 minutes daily to this program, with
                    a total of 5–8 hours per week.
                  </li>
                  <li>Give your full attention and prioritize this course.</li>
                  <li>
                    Have a few clients (formal or informal) to apply your
                    learning to.
                  </li>
                  <li>
                    Work to discover your strengths and your unique coaching
                    approach.
                  </li>
                  <li>
                    Be dedicated to professional self-improvement and ongoing
                    development.
                  </li>
                  <li>Work on written and verbal communication skills.</li>
                  <li>
                    Understand that this certification is more than “just
                    another piece of paper.”
                  </li>
                  <li>
                    Be able to commit for 12 months and manage any disruptions
                    to your schedule proactively.
                  </li>
                  <li>
                    If English is not your first language or if you have a
                    learning disability, take charge of your learning process
                    and get help if necessary.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <span style={{ color: "#17a2b8", fontWeight: "bold" }}>
                  How coaching works in Level 2
                </span>
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: "#e9ecef" }}>
                <p>Level 2 is designed to be relatively self-directed.</p>
                <ul>
                  <li>Every day, you log in.</li>
                  <li>You read your lessons and answer questions.</li>
                  <li>
                    Every 2 weeks, you’ll be assigned a case study or a
                    multiple-choice quiz.
                  </li>
                  <li>
                    You work independently but can also collaborate via a
                    private Facebook group and open "office hours."
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <span style={{ color: "#ffc107", fontWeight: "bold" }}>
                  What your coaches do
                </span>
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: "#f8f9fa" }}>
                <ul>
                  <li>Your coaches answer questions and offer feedback.</li>
                  <li>They review your progress and case studies.</li>
                  <li>They’ll reach out if you're having trouble.</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <span style={{ color: "#dc3545", fontWeight: "bold" }}>
                  What to do today
                </span>
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: "#e9ecef" }}>
                <ul>
                  <li>
                    Review “What you can expect to learn” and “What we expect
                    from you”.
                  </li>
                  <li>
                    Book time during the next several days for PN-related work.
                  </li>
                  <li>
                    Focus on what you need to do today in order to get your
                    lesson read and your daily practice checked off.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Expectations;
