// Auth/Login.js

import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom' // Import the hook for redirection
import { login } from '../../services/authService'
import AuthContext from '../../context/AuthContext'

const Login = () => {
  const { login: handleLogin } = useContext(AuthContext)
  const [credentials, setCredentials] = useState({ email: '', password: '' })
  const navigate = useNavigate() // Initialize the navigate function

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      // console.log('Logging in with credentials:', credentials) // Add this log to check credentials
      const { user, token } = await login(credentials)
      handleLogin(user, token) // Store token and user in context

      // Redirect to the user list page after successful login
      navigate('/users')
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="email"
        placeholder="Email"
        value={credentials.email}
        onChange={(e) =>
          setCredentials({ ...credentials, email: e.target.value })
        }
      />
      <input
        type="password"
        placeholder="Password"
        value={credentials.password}
        onChange={(e) =>
          setCredentials({ ...credentials, password: e.target.value })
        }
      />
      <button type="submit">Login</button>
    </form>
  )
}

export default Login
