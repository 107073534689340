const upperbody1_week3 = [
    {
      name: "Bench Press",
      details: "Barbell",
      sets: [
        { load: "145", reps: 12, rir: 2, rest: "90s" },
        { load: "155", reps: 10, rir: 2, rest: "90s" },
        { load: "165", reps: 8, rir: 2, rest: "90s" },
      ],
      notes: "Keep your wrists straight and elbows at a 45-degree angle.",
    },
    // Add more exercises here
  ];
  
  export default upperbody1_week3;