import React from "react";
import { Button, Card, Container } from "react-bootstrap";
import "./cardSass.scss";

function CardSass() {
  return (
    <Container className="my-5">
      <Card style={{ width: "18rem" }}>
        <Card.Body>
          <Card.Title>React-Bootstrap Card</Card.Title>
          <Card.Text>This is a card created using React-Bootstrap.</Card.Text>
          <Button className="custom-button">Click Me</Button>
        </Card.Body>
      </Card>

      <p className="custom-text">This text is styled using SASS.</p>
    </Container>
  );
}

export default CardSass;
