// src/routes/AnimalsRoutes.js
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MapAllObjToFormAccordion from '../components/Javascript/MapAllObjToFormAccordion'
import MapFirstObject from '../components/Javascript/MapFirstObject'
import MapAllObjDestructureCards from '../components/Javascript/MapAllObjDestructureCards'
import ArrayWithColorObjects from '../components/Javascript/ArrayWithColorObjects'
import ArrayLowerBodyOne from '../components/Javascript/ArrayLowerBodyOne'
import Practice from '../components/Javascript/Practice'

const AnimalsRoutes = () => {
  return (
    <Routes>
      {/* Javascript routes */}
      <Route
        path="/mapAllObjToFormAccordion"
        element={<MapAllObjToFormAccordion />}
      />
      <Route
        path="/mapAllObjDestructureCards"
        element={<MapAllObjDestructureCards />}
      />
      <Route
        path="/arrayWithColorObjects"
        element={<ArrayWithColorObjects />}
      />
      <Route path="/mapFirstObject" element={<MapFirstObject />} />
      <Route path="/arrayLowerBodyOne" element={<ArrayLowerBodyOne />} />
      <Route path="/practice" element={<Practice />} />
    </Routes>
  )
}

export default AnimalsRoutes
