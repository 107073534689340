import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Card, Button, Container, Row, Col } from 'react-bootstrap' // Import necessary Bootstrap components

// Import Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css'

function Exercises() {
  const [exercises, setExercises] = useState([])

  useEffect(() => {
    axios
      .get('http://localhost:5002/react-api/exercises')
      .then((response) => {
        setExercises(response.data)
      })
      .catch((error) => {
        console.error('There was an error fetching the exercises!', error)
      })
  }, []) // Runs once when the component mounts

  return (
    <Container>
      <h1 className="my-4">Exercise List</h1>
      <Row>
        {exercises.map((exercise) => (
          <Col key={exercise._id} md={4} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>{exercise.name}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  {exercise.type}
                </Card.Subtitle>
                <Card.Text>
                  <strong>Details:</strong>{' '}
                  {exercise.details || 'No details provided'}
                </Card.Text>
                <Card.Text>
                  <strong>Sets:</strong>
                  {exercise.sets.map((set, index) => (
                    <div key={index}>
                      Load: {set.load} lbs, Reps: {set.reps}, Rest: {set.rest},
                      RIR: {set.rir}
                    </div>
                  ))}
                </Card.Text>
                <Card.Text>
                  <strong>Notes:</strong> {exercise.notes || 'No notes'}
                </Card.Text>
                <Button variant="primary">View Exercise</Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default Exercises
