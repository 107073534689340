import React, { useState, useEffect } from "react";

const Exercise = ({ name, details, sets, notes }) => {
  const [scores, setScores] = useState({
    connection: 0,
    disruption: 0,
    pump: 0,
  });
  const [totalScore, setTotalScore] = useState(0);

  useEffect(() => {
    const total = scores.connection + scores.disruption + scores.pump;
    setTotalScore(total);
  }, [scores]);

  const handleScoreChange = (type, value) => {
    const numValue = parseInt(value, 10);
    if (numValue >= 0 && numValue <= 3) {
      setScores((prev) => ({ ...prev, [type]: numValue }));
    }
  };

  return (
    <>
      <tr>
        <td rowSpan="4">{name}</td>
        <td>Load</td>
        {sets.map((set, index) => (
          <td key={index}>{set.load !== "N/A" ? set.load : "N/A"}</td>
        ))}
        <td>Connection</td>
        <td>
          <input
            type="number"
            min="0"
            max="3"
            value={scores.connection}
            onChange={(e) => handleScoreChange("connection", e.target.value)}
          />
        </td>
        <td rowSpan="4">{notes}</td>
      </tr>

      <tr>
        <td>Reps</td>
        {sets.map((set, index) => (
          <td key={index}>{set.reps !== "N/A" ? set.reps : "N/A"}</td>
        ))}
        {/* Updated to handle "N/A" */}
        <td>Disruption</td>
        <td>
          <input
            type="number"
            min="0"
            max="3"
            value={scores.disruption}
            onChange={(e) => handleScoreChange("disruption", e.target.value)}
          />
        </td>
      </tr>
      <tr>
        <td>RIR</td>
        {sets.map((set, index) => (
          <td key={index}>{set.rir !== "N/A" ? set.rir : "N/A"}</td>
        ))}
        <td>Pump</td>
        <td>
          <input
            type="number"
            min="0"
            max="3"
            value={scores.pump}
            onChange={(e) => handleScoreChange("pump", e.target.value)}
          />
        </td>
      </tr>
      <tr>
        <td>Rest</td>
        {sets.map((set, index) => (
          <td key={index}>{set.rest !== "N/A" ? set.rest : "N/A"}</td>
        ))}
        <td>Total</td>
        <td>{totalScore}</td>
      </tr>
    </>
  );
};

export default Exercise;
