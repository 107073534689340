// src/routes/AnimalsRoutes.js
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import UserList from '../components/Users/UserList' // Protected route
import ProtectedRoute from '../components/Auth/ProtectedRoute'

const UserRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <UserList />
          </ProtectedRoute>
        }
      />
    </Routes>
  )
}

export default UserRoutes
