// Auth/ProtectedRoute.js
// Not sure where this file should live

import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import AuthContext from '../../context/AuthContext'

const ProtectedRoute = ({ children }) => {
  const { auth } = useContext(AuthContext)

  if (!auth.token) {
    return <Navigate to="/auth/login" />
  }

  return children
}

export default ProtectedRoute
