import React from "react";
import { Container, Accordion, Card } from "react-bootstrap";

const LevelTwoNutsAndBolts = () => {
  return (
    <Container fluid="md" className="my-4">
      <Card style={{ backgroundColor: "#f8f9fa", borderColor: "#e9ecef" }}>
        <Card.Body>
          <Card.Title as="h1" className="mb-4 text-primary">
            Level 2 Nuts and Bolts
          </Card.Title>
          <Card.Text>How to use your "Today" page and FAQ.</Card.Text>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header
                style={{ backgroundColor: "#007bff", color: "#fff" }}
              >
                A few more things to know
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: "#e9ecef" }}>
                <p>In today's lesson:</p>
                <ul>
                  <li>
                    You'll learn more about your "Today" page and other types of
                    cards.
                  </li>
                  <li>
                    We'll explain why we use a "Today" page in the first place.
                  </li>
                  <li>We'll answer some frequently asked questions.</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <span style={{ color: "#28a745", fontWeight: "bold" }}>
                  How to use your "Today" page
                </span>
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: "#f8f9fa" }}>
                <p>Here's a quick refresher.</p>
                <p>
                  Each day when you log in, you'll see "cards" (the colored
                  boxes) on your page:
                </p>
                <ul>
                  <li>A daily lesson card</li>
                  <li>A daily practice card</li>
                  <li>Sometimes, you'll see:</li>
                  <ul>
                    <li>A case study card</li>
                    <li>A quiz card</li>
                    <li>A self-assessment card</li>
                  </ul>
                </ul>
                <p>
                  Click on the cards that pop up and follow the instructions on
                  them. Let's walk through examples of each below:
                </p>

                <h5>To complete a lesson:</h5>
                <ul>
                  <li>Click on the lesson card.</li>
                  <li>
                    Read the lesson, paying special attention to the "What to do
                    today" section.
                  </li>
                  <li>
                    Answer any questions if required, and click "I've read this
                    lesson."
                  </li>
                </ul>

                <h5>To complete a daily practice:</h5>
                <ul>
                  <li>Click on the daily practice card.</li>
                  <li>
                    If it's new, open the card for a full explanation. If it's
                    familiar, quickly click "YES" or "NO".
                  </li>
                </ul>

                <h5>To complete a case study:</h5>
                <ul>
                  <li>
                    Read the instructions on the case study card (delivered
                    every other Thursday).
                  </li>
                  <li>
                    If a written assignment is required, paste your answer into
                    the submission card and click "Submit".
                  </li>
                  <li>
                    Written case studies are due the Wednesday before the next
                    case study is released.
                  </li>
                </ul>

                <h5>To complete a quiz:</h5>
                <ul>
                  <li>Click on the quiz card.</li>
                  <li>
                    Answer the multiple-choice questions and click "Submit
                    quiz".
                  </li>
                </ul>

                <h5>To self-assess your progress:</h5>
                <ul>
                  <li>
                    Look for the "How are you doing?" card, which appears every
                    other Saturday, starting in week 3.
                  </li>
                  <li>Answer the questions and click "Share your answers".</li>
                </ul>

                <h5>Your Course Archive:</h5>
                <p>
                  Once you complete a lesson, daily practice, case study, or
                  quiz, it goes into your Course Archive. Activities that still
                  need to be completed will show up as brightly colored, while
                  completed ones will appear faded.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <span style={{ color: "#17a2b8", fontWeight: "bold" }}>
                  Frequently Asked Questions
                </span>
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: "#e9ecef" }}>
                <h5>
                  What does this program cover? I'd like to know in advance.
                </h5>
                <p>
                  Check out the Level 2 Coaching Certification Course Syllabus
                  we gave you on the first day of the program.
                </p>

                <h5>So I can't see all the lessons beforehand?</h5>
                <p>
                  No, you'll get only what you should be focusing on today. If
                  you know a major life disruption is coming, contact your coach
                  to plan accordingly.
                </p>

                <h5>What if I need to take a break?</h5>
                <p>
                  The Level 2 program is designed to accommodate busy schedules.
                  If you fall behind, catch up at your own pace.
                </p>

                <h5>What if I need to drop out?</h5>
                <p>
                  If life happens and you need to leave the program, please
                  contact your coach.
                </p>

                <h5>What if I find a bug in the software?</h5>
                <p>
                  Use the "Tech Support" link in your sidebar. Try to be as
                  specific as possible about the problem.
                </p>

                <h5>Do I have to do "Further reading"?</h5>
                <p>
                  Nope — it's there for extra learning if you're interested.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <span style={{ color: "#ffc107", fontWeight: "bold" }}>
                  What to do today
                </span>
              </Accordion.Header>
              <Accordion.Body style={{ backgroundColor: "#f8f9fa" }}>
                <ul>
                  <li>
                    Review the elements of your Today page and Course Archive.
                  </li>
                  <li>Take your time and get familiar with them.</li>
                  <li>Focus on your daily practice and today's lesson.</li>
                </ul>
                <p>
                  And that's it for now. We'll tell you whatever you need to
                  know, when you need to know it.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <p className="mt-4">And that's all for today!</p>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default LevelTwoNutsAndBolts;
