import { Table } from "react-bootstrap";

// Meal Planner Component
const MealPlanner = () => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const meals = ["1", "2", "3", "4"];

  const mealPlan = [
    ["P-Coffee, Eggs & Fruit", "Chicken & Salad", "Paella", "None"],
    ["Turkey Chili", "Turkey Burger", "BBQ Chicken", "None"],
    [
      "Rice, Chicken, skirt, clementines, p-coffee",
      "chicken,skirt,salad",
      "Salmon",
      "None",
    ],
    [
      "Rice, Chicken, skirt, clementines, p-coffee",
      "chicken,skirt,salad",
      "Salmon & Broccoli",
      "None",
    ],
    ["P-coffee", "Chicken,skirt,salad", "Mall", "None"],
    ["P-coffee", "Chicken,skirt,salad", "Movies", "None"],
    ["Eggs & Salad", "Chicken,skirt,salad", "Pizza", "None"],
  ];

  return (
    <div>
      <h2>Weekly Meal Planner</h2>
      <div className="table-responsive">
        {/* Makes table scrollable on small screens */}
        <Table bordered>
          <thead>
            <tr>
              <th>Meals</th>
              {daysOfWeek.map((day, dayIndex) => (
                <th key={dayIndex}>{day}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {meals.map((meal, mealIndex) => (
              <tr key={mealIndex}>
                <td>{meal}</td>
                {mealPlan.map((dayMeals, dayIndex) => (
                  <td key={`${dayIndex}-${mealIndex}`}>
                    {dayMeals[mealIndex]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default MealPlanner;
