import React, { useState } from 'react'
import axios from 'axios'
import { Form, Button, Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom' // Import useNavigate

function CreateExercise() {
  const navigate = useNavigate() // Initialize useNavigate for navigation

  // State for all exercise fields
  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [details, setDetails] = useState('')
  const [sets, setSets] = useState([{ load: '', reps: '', rest: '', rir: '' }])
  const [date, setDate] = useState('')
  const [notes, setNotes] = useState('')
  const [settings, setSettings] = useState('')
  // Fetch the API URL from the environment variable
  const API_URL =
    process.env.REACT_APP_API_URL || 'http://localhost:5002/react-api' // Fallback to localhost
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await axios.post(`${API_URL}/exercises`, {
        name,
        type,
        details,
        sets,
        date,
        notes,
        settings,
      })
      alert('Exercise created successfully')

      // Redirect to the exercises list page
      navigate('/exercises/readAllExercises') // Adjust the path as necessary
    } catch (error) {
      console.error('Error creating exercise', error)
    }
  }

  // Function to handle changes in sets
  const handleSetChange = (index, field, value) => {
    const updatedSets = [...sets]
    updatedSets[index][field] = value
    setSets(updatedSets)
  }

  // Add a new set
  const addSet = () => {
    setSets([...sets, { load: '', reps: '', rest: '', rir: '' }])
  }

  return (
    <Container>
      <h1>Create Exercise</h1>
      <Form onSubmit={handleSubmit}>
        {/* Name Field */}
        <Form.Group controlId="name">
          <Form.Label>Exercise Name</Form.Label>
          <Form.Control
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Form.Group>

        {/* Type Field */}
        <Form.Group controlId="type">
          <Form.Label>Exercise Type</Form.Label>
          <Form.Control
            type="text"
            value={type}
            onChange={(e) => setType(e.target.value)}
            required
          />
        </Form.Group>

        {/* Details Field */}
        <Form.Group controlId="details">
          <Form.Label>Exercise Details</Form.Label>
          <Form.Control
            type="text"
            value={details}
            onChange={(e) => setDetails(e.target.value)}
          />
        </Form.Group>

        {/* Date Field */}
        <Form.Group controlId="date">
          <Form.Label>Date</Form.Label>
          <Form.Control
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </Form.Group>

        {/* Notes Field */}
        <Form.Group controlId="notes">
          <Form.Label>Notes</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </Form.Group>

        {/* Settings Field */}
        <Form.Group controlId="settings">
          <Form.Label>Settings</Form.Label>
          <Form.Control
            type="text"
            value={settings}
            onChange={(e) => setSettings(e.target.value)}
          />
        </Form.Group>

        {/* Sets Section */}
        <h4>Sets</h4>
        {sets.map((set, index) => (
          <div key={index} className="mb-3">
            <Form.Group controlId={`set-load-${index}`}>
              <Form.Label>Load (lbs)</Form.Label>
              <Form.Control
                type="number"
                value={set.load}
                onChange={(e) => handleSetChange(index, 'load', e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId={`set-reps-${index}`}>
              <Form.Label>Reps</Form.Label>
              <Form.Control
                type="number"
                value={set.reps}
                onChange={(e) => handleSetChange(index, 'reps', e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId={`set-rest-${index}`}>
              <Form.Label>Rest (seconds)</Form.Label>
              <Form.Control
                type="text"
                value={set.rest}
                onChange={(e) => handleSetChange(index, 'rest', e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId={`set-rir-${index}`}>
              <Form.Label>RIR (Reps in Reserve)</Form.Label>
              <Form.Control
                type="number"
                value={set.rir}
                onChange={(e) => handleSetChange(index, 'rir', e.target.value)}
                required
              />
            </Form.Group>
          </div>
        ))}
        <Button variant="secondary" onClick={addSet}>
          Add Another Set
        </Button>

        {/* Submit Button */}
        <Button variant="primary" type="submit" className="mt-4">
          Create
        </Button>
      </Form>
    </Container>
  )
}

export default CreateExercise

// import React, { useState } from 'react'
// import axios from 'axios'
// import { Form, Button, Container } from 'react-bootstrap'

// function CreateExercise() {
//   // State for all exercise fields
//   const [name, setName] = useState('')
//   const [type, setType] = useState('')
//   const [details, setDetails] = useState('')
//   const [sets, setSets] = useState([{ load: '', reps: '', rest: '', rir: '' }])
//   const [date, setDate] = useState('')
//   const [notes, setNotes] = useState('')
//   const [settings, setSettings] = useState('')

//   // Handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault()
//     try {
//       await axios.post('http://localhost:5002/react-api/exercises', {
//         name,
//         type,
//         details,
//         sets,
//         date,
//         notes,
//         settings,
//       })
//       alert('Exercise created successfully')
//     } catch (error) {
//       console.error('Error creating exercise', error)
//     }
//   }

//   // Function to handle changes in sets
//   const handleSetChange = (index, field, value) => {
//     const updatedSets = [...sets]
//     updatedSets[index][field] = value
//     setSets(updatedSets)
//   }

//   // Add a new set
//   const addSet = () => {
//     setSets([...sets, { load: '', reps: '', rest: '', rir: '' }])
//   }

//   return (
//     <Container>
//       <h1>Create Exercise</h1>
//       <Form onSubmit={handleSubmit}>
//         {/* Name Field */}
//         <Form.Group controlId="name">
//           <Form.Label>Exercise Name</Form.Label>
//           <Form.Control
//             type="text"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             required
//           />
//         </Form.Group>

//         {/* Type Field */}
//         <Form.Group controlId="type">
//           <Form.Label>Exercise Type</Form.Label>
//           <Form.Control
//             type="text"
//             value={type}
//             onChange={(e) => setType(e.target.value)}
//             required
//           />
//         </Form.Group>

//         {/* Details Field */}
//         <Form.Group controlId="details">
//           <Form.Label>Exercise Details</Form.Label>
//           <Form.Control
//             type="text"
//             value={details}
//             onChange={(e) => setDetails(e.target.value)}
//           />
//         </Form.Group>

//         {/* Date Field */}
//         <Form.Group controlId="date">
//           <Form.Label>Date</Form.Label>
//           <Form.Control
//             type="date"
//             value={date}
//             onChange={(e) => setDate(e.target.value)}
//           />
//         </Form.Group>

//         {/* Notes Field */}
//         <Form.Group controlId="notes">
//           <Form.Label>Notes</Form.Label>
//           <Form.Control
//             as="textarea"
//             rows={3}
//             value={notes}
//             onChange={(e) => setNotes(e.target.value)}
//           />
//         </Form.Group>

//         {/* Settings Field */}
//         <Form.Group controlId="settings">
//           <Form.Label>Settings</Form.Label>
//           <Form.Control
//             type="text"
//             value={settings}
//             onChange={(e) => setSettings(e.target.value)}
//           />
//         </Form.Group>

//         {/* Sets Section */}
//         <h4>Sets</h4>
//         {sets.map((set, index) => (
//           <div key={index} className="mb-3">
//             <Form.Group controlId={`set-load-${index}`}>
//               <Form.Label>Load (lbs)</Form.Label>
//               <Form.Control
//                 type="number"
//                 value={set.load}
//                 onChange={(e) => handleSetChange(index, 'load', e.target.value)}
//                 required
//               />
//             </Form.Group>

//             <Form.Group controlId={`set-reps-${index}`}>
//               <Form.Label>Reps</Form.Label>
//               <Form.Control
//                 type="number"
//                 value={set.reps}
//                 onChange={(e) => handleSetChange(index, 'reps', e.target.value)}
//                 required
//               />
//             </Form.Group>

//             <Form.Group controlId={`set-rest-${index}`}>
//               <Form.Label>Rest (seconds)</Form.Label>
//               <Form.Control
//                 type="text"
//                 value={set.rest}
//                 onChange={(e) => handleSetChange(index, 'rest', e.target.value)}
//                 required
//               />
//             </Form.Group>

//             <Form.Group controlId={`set-rir-${index}`}>
//               <Form.Label>RIR (Reps in Reserve)</Form.Label>
//               <Form.Control
//                 type="number"
//                 value={set.rir}
//                 onChange={(e) => handleSetChange(index, 'rir', e.target.value)}
//                 required
//               />
//             </Form.Group>
//           </div>
//         ))}
//         <Button variant="secondary" onClick={addSet}>
//           Add Another Set
//         </Button>

//         {/* Submit Button */}
//         <Button variant="primary" type="submit" className="mt-4">
//           Create
//         </Button>
//       </Form>
//     </Container>
//   )
// }

// export default CreateExercise

// import React, { useState } from 'react'
// import axios from 'axios'
// import { Form, Button, Container } from 'react-bootstrap'

// function CreateExercise() {
//   const [name, setName] = useState('')
//   const [type, setType] = useState('')
//   const [sets, setSets] = useState([{ load: '', reps: '', rest: '', rir: '' }])

//   const handleSubmit = async (e) => {
//     e.preventDefault()
//     try {
//       await axios.post('http://localhost:5002/react-api/exercises', {
//         name,
//         type,
//         sets,
//       })
//       alert('Exercise created successfully')
//     } catch (error) {
//       console.error('Error creating exercise', error)
//     }
//   }

//   return (
//     <Container>
//       <h1>Create Exercise</h1>
//       <Form onSubmit={handleSubmit}>
//         <Form.Group controlId="name">
//           <Form.Label>Exercise Name</Form.Label>
//           <Form.Control
//             type="text"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             required
//           />
//         </Form.Group>
//         <Form.Group controlId="type">
//           <Form.Label>Exercise Type</Form.Label>
//           <Form.Control
//             type="text"
//             value={type}
//             onChange={(e) => setType(e.target.value)}
//             required
//           />
//         </Form.Group>
//         {/* Add UI for adding sets */}
//         <Button variant="primary" type="submit">
//           Create
//         </Button>
//       </Form>
//     </Container>
//   )
// }

// export default CreateExercise
