import React from 'react'
import axios from 'axios'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom' // Import useNavigate

function DeleteExercise({ id, onDelete }) {
  const navigate = useNavigate() // Initialize useNavigate for navigation
  const handleDelete = async () => {
    try {
      await axios.delete(`http://localhost:5002/react-api/exercises/${id}`)
      onDelete(id)
      // Redirect to the exercises list page
      navigate('/exercises/readAllExercises') // Adjust the path as necessary
    } catch (error) {
      console.error('Error deleting exercise', error)
    }
  }

  return (
    <Button variant="danger" onClick={handleDelete}>
      Delete
    </Button>
  )
}

export default DeleteExercise
