import React from "react";
import { Card, Col, Row, Container } from "react-bootstrap";

const emotionExperienceData = [
  {
    category: "Things Are Uncertain Or Too Much",
    experiences: [
      "Stress",
      "Overwhelm",
      "Anxiety",
      "Worry",
      "Avoidance",
      "Excitement",
      "Dread",
      "Fear",
      "Vulnerability",
    ],
  },
  {
    category: "We're Hurting",
    experiences: ["Anguish", "Hopelessness", "Despair", "Sadness", "Grief"],
  },
  {
    category: "Life Is Good",
    experiences: [
      "Joy",
      "Happiness",
      "Calm",
      "Contentment",
      "Gratitude",
      "Foreboding Joy",
      "Relief",
      "Tranquility",
    ],
  },
  {
    category: "Things Don't Go As Planned",
    experiences: [
      "Boredom",
      "Disappointment",
      "Expectations",
      "Regret",
      "Discouragement",
      "Resignation",
    ],
  },
  {
    category: "We Fall Short",
    experiences: [
      "Frustration",
      "Shame",
      "Self-Compassion",
      "Perfectionism",
      "Guilt",
      "Humiliation",
      "Embarrassment",
    ],
  },
  { category: "To Self-Assess", experiences: ["Pride", "Hubris", "Humility"] },
  {
    category: "We Compare With Others",
    experiences: [
      "Comparison",
      "Admiration",
      "Reverence",
      "Envy",
      "Jealousy",
      "Resentment",
      "Schadenfreude",
      "Freudenfreude",
    ],
  },
  {
    category: "We Feel Wronged",
    experiences: [
      "Anger",
      "Contempt",
      "Disgust",
      "Dehumanization",
      "Hate",
      "Self-Righteousness",
    ],
  },
  {
    category: "It's Beyond Us",
    experiences: [
      "Awe",
      "Wonder",
      "Confusion",
      "Curiosity",
      "Interest",
      "Surprise",
    ],
  },
  {
    category: "We Search for Connection",
    experiences: [
      "Belonging",
      "Fitting In",
      "Connection",
      "Disconnection",
      "Insecurity",
      "Invisibility",
      "Loneliness",
    ],
  },
  {
    category: "Things Aren't What They Seem",
    experiences: [
      "Amusement",
      "Bittersweetness",
      "Nostalgia",
      "Cognitive Dissonance",
      "Paradox",
      "Irony",
      "Sarcasm",
    ],
  },
  {
    category: "The Heart Is Open",
    experiences: [
      "Love",
      "Lovelessness",
      "Heartbreak",
      "Trust",
      "Self-Trust",
      "Betrayal",
      "Defensiveness",
      "Flooding",
      "Hurt",
    ],
  },
  {
    category: "Places We Go",
    experiences: [
      "Compassion",
      "Pity",
      "Empathy",
      "Sympathy",
      "Boundaries",
      "Comparative Suffering",
    ],
  },
];
const EmotionCard = ({ category, experiences }) => (
  <Card className="mb-3">
    {" "}
    <Card.Body>
      {" "}
      <Card.Title>{category}</Card.Title>{" "}
      <ul className="list-unstyled">
        {" "}
        {experiences.map((experience, index) => (
          <li key={index}>{experience}</li>
        ))}{" "}
      </ul>{" "}
    </Card.Body>{" "}
  </Card>
);
const EmotionCardList = () => (
  <Container>
    {" "}
    <h1 className="text-center my-4">87 Human Emotions & Experiences</h1>{" "}
    <Row>
      {" "}
      {emotionExperienceData.map((data, index) => (
        <Col md={4} key={index}>
          {" "}
          <EmotionCard {...data} />{" "}
        </Col>
      ))}{" "}
    </Row>{" "}
  </Container>
);
export default EmotionCardList;
