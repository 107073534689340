// Auth/Signup.js

import React, { useState } from 'react'
import { signup } from '../../services/authService'
import { useNavigate } from 'react-router-dom' // Import useNavigate

const Signup = () => {
  const [userData, setUserData] = useState({
    username: '',
    email: '',
    password: '',
  })

  const navigate = useNavigate() // Initialize useNavigate

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log(userData)
    try {
      await signup(userData)
      // Redirect to the login page after successful signup
      navigate('/auth/login') // Redirect to the login page
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Username"
        value={userData.username}
        onChange={(e) => setUserData({ ...userData, username: e.target.value })}
      />
      <input
        type="email"
        placeholder="Email"
        value={userData.email}
        onChange={(e) => setUserData({ ...userData, email: e.target.value })}
      />
      <input
        type="password"
        placeholder="Password"
        value={userData.password}
        onChange={(e) => setUserData({ ...userData, password: e.target.value })}
      />
      <button type="submit">Sign Up</button>
    </form>
  )
}

export default Signup
