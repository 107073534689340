import React from 'react'
import lowerbody1Data from '../DBLocal/lowerbody/lowerbody1_week1'
import { Container, Row, Col, Card } from 'react-bootstrap'
import './practice.scss'

const ArrayWithColorObjects = () => {
  const arrayWithColorObjects = [
    { red: '#FF0000' },
    { blue: '#0000FF' },
    { green: '#008000' },
    { yellow: '#FFFF00' },
    { orange: '#FFA500' },
    { purple: '#800080' },
    { pink: '#FFC0CB' },
    { lightblue: '#ADD8E6' },
    { darkblue: '#00008B' },
  ]

  const objectWithColors = {
    red: '#FF0000',
    blue: '#0000FF',
    green: '#008000',
    yellow: '#FFFF00',
    orange: '#FFA500',
    purple: '#800080',
    pink: '#FFC0CB',
    lightblue: '#ADD8E6',
    darkblue: '#00008B',
  }
  const arrayWithColorArrays = [
    ['red', '#FF0000'],
    ['blue', '#0000FF'],
    ['green', '#008000'],
    ['yellow', '#FFFF00'],
    ['orange', '#FFA500'],
    ['purple', '#800080'],
    ['pink', '#FFC0CB'],
    ['lightblue', '#ADD8E6'],
    ['darkblue', '#00008B'],
  ]

  const arrayWhithAnObjThatHasAnObjOfColors = [
    {
      color: {
        red: '#FF0000',
        blue: '#0000FF',
        green: '#008000',
        yellow: '#FFFF00',
        orange: '#FFA500',
        purple: '#800080',
        pink: '#FFC0CB',
        lightblue: '#ADD8E6',
        darkblue: '#00008B',
      },
    },
  ]

  return (
    <>
      <Container>
        <Row>
          <Col>
            <ul>
              {arrayWithColorObjects.map((colorObject, index) => {
                const [colorName, hexValue] = Object.entries(colorObject)[0]
                return (
                  <li key={index} style={{ color: hexValue }}>
                    {colorName}
                  </li>
                )
              })}
            </ul>
          </Col>
          <Col>
            <h1 style={{ color: 'red' }}>h1 red</h1>
            <h2 style={{ color: 'blue' }}>h2 blue </h2>
            <h3 style={{ color: 'green' }}>h3 blue </h3>
            <h4 style={{ color: 'yellow' }}>h4 blue </h4>
            <h5 style={{ color: 'orange' }}>h2 blue </h5>
            <h6 style={{ color: 'purple' }}>h2 blue </h6>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ArrayWithColorObjects
