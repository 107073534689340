const upperbody1_week2 = [
    {
      name: "Bench Press",
      details: "Barbell",
      sets: [
        { load: "140", reps: 12, rir: 3, rest: "90s" },
        { load: "150", reps: 10, rir: 3, rest: "90s" },
        { load: "160", reps: 8, rir: 3, rest: "90s" },
      ],
      notes: "Keep your wrists straight and elbows at a 45-degree angle.",
    },
    // Add more exercises here for week 2...
  ];
  
  export default upperbody1_week2;