import React, { useState } from 'react'
import lowerbody1Data from '../DBLocal/lowerbody/lowerbody1_week1'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Accordion from 'react-bootstrap/Accordion'

const Map = () => {
  const [activeKey, setActiveKey] = useState(null) // Track the active accordion

  const handleCellChange = (rowIndex, cellName, newValue) => {
    const updatedData = [...lowerbody1Data]
    updatedData[rowIndex][cellName] = newValue
  }

  const handleSetChange = (exerciseIndex, setIndex, newValue) => {
    const updatedData = [...lowerbody1Data]
    updatedData[exerciseIndex].sets[setIndex].load = newValue
  }

  const toggleAccordion = (key) => {
    // Toggle the accordion: if the same key is clicked again, close it (set to null)
    setActiveKey(activeKey === key ? null : key)
  }

  return (
    <div>
      <div style={{ height: '100%' }}>
        <p>Map an Array</p>
        <form>
          <Accordion activeKey={activeKey}>
            {lowerbody1Data.map((item, index) => (
              <Card key={index}>
                <Accordion.Item eventKey={index.toString()}>
                  <Accordion.Header
                    onClick={() => toggleAccordion(index.toString())}
                  >
                    {item.name}
                  </Accordion.Header>
                  <Accordion.Body>
                    <Card.Body>
                      <Form>
                        <Form.Group>
                          <Form.Label>Details</Form.Label>
                          <Form.Control
                            type="text"
                            value={item.details}
                            onChange={(e) =>
                              handleCellChange(index, 'details', e.target.value)
                            }
                          />
                        </Form.Group>

                        {item.sets.map((set, setIndex) => (
                          <Form.Group key={setIndex}>
                            <Form.Label>Set {setIndex + 1} Load</Form.Label>
                            <Form.Control
                              type="number"
                              value={set.load}
                              onChange={(e) =>
                                handleSetChange(index, setIndex, e.target.value)
                              }
                            />
                          </Form.Group>
                        ))}

                        <Button variant="primary" type="submit">
                          Save
                        </Button>
                      </Form>
                    </Card.Body>
                  </Accordion.Body>
                </Accordion.Item>
              </Card>
            ))}
          </Accordion>
        </form>
      </div>
    </div>
  )
}

export default Map
