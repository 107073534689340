import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Card, Button, Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom' // Import Link for navigation

// Import Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css'

function ReadAllExercises() {
  const [exercises, setExercises] = useState([])
  // Fetch the API URL from the environment variable
  const API_URL =
    process.env.REACT_APP_API_URL || 'http://localhost:5002/react-api' // Fallback to localhost
  useEffect(() => {
    // Log the API URL
    // console.log(`Fetching data from: ${API_URL}/exercises`)
    axios
      .get(`${API_URL}/exercises`)
      // .get('https://hermcore.com/react-api/exercises')
      // .get('https://hermcore:5001/react-api/exercises')
      // .get('http://localhost:5002/react-api/exercises')
      .then((response) => {
        // console.log(response)
        setExercises(response.data)
      })
      .catch((error) => {
        console.error('There was an error fetching the exercises!', error)
      })
  }, []) // Runs once when the component mounts

  return (
    <Container>
      <h1 className="my-4">Exercises</h1>
      <Row>
        {exercises.map((exercise) => (
          <Col key={exercise._id} md={4} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>{exercise.name}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  {exercise.type}
                </Card.Subtitle>
                <Card.Text>
                  <strong>Details:</strong>{' '}
                  {exercise.details || 'No details provided'}
                </Card.Text>
                {/* <Card.Text>
                  <strong>Sets:</strong>
                  {exercise.sets.map((set, index) => (
                    <div key={index}>
                      Load: {set.load} lbs, Reps: {set.reps}, Rest: {set.rest},
                      RIR: {set.rir}
                    </div>
                  ))}
                </Card.Text> */}
                <Card.Text>
                  <strong>Sets:</strong>
                  {exercise.sets.map((set, index) => (
                    <span key={index}>
                      Load: {set.load} lbs, Reps: {set.reps}, Rest: {set.rest},
                      RIR: {set.rir}
                      <br /> {/* Use <br /> to separate sets */}
                    </span>
                  ))}
                </Card.Text>
                <Card.Text>
                  <strong>Notes:</strong> {exercise.notes || 'No notes'}
                </Card.Text>
                {/* Add a Link to navigate to the ViewExercise page */}
                <Link to={`/exercises/readExercise/${exercise._id}`}>
                  <Button variant="primary">View Exercise</Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default ReadAllExercises

// import React, { useState, useEffect } from 'react'
// import axios from 'axios'
// import { Card, Button, Container, Row, Col } from 'react-bootstrap' // Import necessary Bootstrap components

// // Import Bootstrap CSS
// import 'bootstrap/dist/css/bootstrap.min.css'

// function ReadAllExercises() {
//   const [exercises, setExercises] = useState([])

//   useEffect(() => {
//     axios
//       .get('http://localhost:5002/react-api/exercises')
//       .then((response) => {
//         setExercises(response.data)
//       })
//       .catch((error) => {
//         console.error('There was an error fetching the exercises!', error)
//       })
//   }, []) // Runs once when the component mounts

//   return (
//     <Container>
//       <h1 className="my-4">Exercise List</h1>
//       <Row>
//         {exercises.map((exercise) => (
//           <Col key={exercise._id} md={4} className="mb-4">
//             <Card>
//               <Card.Body>
//                 <Card.Title>{exercise.name}</Card.Title>
//                 <Card.Subtitle className="mb-2 text-muted">
//                   {exercise.type}
//                 </Card.Subtitle>
//                 <Card.Text>
//                   <strong>Details:</strong>{' '}
//                   {exercise.details || 'No details provided'}
//                 </Card.Text>
//                 <Card.Text>
//                   <strong>Sets:</strong>
//                   {exercise.sets.map((set, index) => (
//                     <div key={index}>
//                       Load: {set.load} lbs, Reps: {set.reps}, Rest: {set.rest},
//                       RIR: {set.rir}
//                     </div>
//                   ))}
//                 </Card.Text>
//                 <Card.Text>
//                   <strong>Notes:</strong> {exercise.notes || 'No notes'}
//                 </Card.Text>
//                 <Button variant="primary">View Exercise</Button>
//               </Card.Body>
//             </Card>
//           </Col>
//         ))}
//       </Row>
//     </Container>
//   )
// }

// export default ReadAllExercises
